import { Link } from 'react-router-dom';
import type { LinkProps, To } from 'react-router-dom';
import type { Route } from '../types';
import { resolve } from '../resolve';

export interface RouterLinkProps extends LinkProps {
    to: Route | To;
}

export function RouterLink(props: RouterLinkProps) {
    const mappedProps = {
        ...props,
        to: isPartialRouteObject(props.to)
            ? resolve(props.to.name, props.to.params, props.to.query, props.to.hash)
            : props.to,
        state: isPartialRouteObject(props.to) ? props.to.data : null,
    };

    return (
        <Link
            {...mappedProps}
            style={{
                textDecoration: 'none',
                color: 'inherit',
            }}
        />
    );
}

function isPartialRouteObject(to: unknown): to is Route {
    return typeof to === 'object'
        && to !== null
        && 'name' in to
        && typeof 'name' === 'string';
}
