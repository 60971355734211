import { reverse } from 'named-urls';
import type { ParamsObject } from './types';
import { getPathPatternFromName } from './routes';

export function resolve<
    Params extends ParamsObject,
    Query extends ParamsObject
>(
    name: string,
    params: Params = {} as Params,
    query: Query = {} as Query,
    hash: string = '',
): string {
    const pattern = getPathPatternFromName(name);
    if (typeof pattern !== 'string') {
        console.error(new Error(`Navigation error: cannot find "${name}" route.`));
        return '';
    }
    return `${reverse(pattern, params)}${getQueryString(query)}${getHashString(hash)}`;
}

function getHashString(hash: string): string {
    if (!hash.length || hash === '#') {
        return '';
    }
    return hash.startsWith('#') ? hash : `#${hash}`;
}

function getQueryString(query: ParamsObject) {
    if (typeof query === 'object' && query !== null && !Object.keys(query).length) {
        return '';
    }
    return `?${new URLSearchParams(query).toString()}`;
}
