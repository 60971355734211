import { SecondaryButton, SuccessButton } from '@/modules/ui/button';
import { Page } from '@/modules/ui/page';
import { mdiArrowLeft, mdiFloppy } from '@mdi/js';
import { RouteNames } from '../routes';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import React, { useRef, useState } from 'react';
import { useRouterNavigation } from '@/router';
import { toMessagesList, useToasts } from '@/modules/toasts';
import { useFormik } from 'formik';
import type { CreateTeamDTO } from '@hnx-archivr/ownership';
import { getErrorMessages, withJoiSchema } from '@/core';
import { api } from '../api';
import { createTeamValidator } from '../validators';
import deburr from 'lodash/deburr';
import kebabCase from 'lodash/kebabCase';

export function CreateTeam() {
    const [loading, setLoading] = useState(false);
    const slugTouched = useRef(false);
    const { replace } = useRouterNavigation();
    const { success, error } = useToasts();

    const form = useFormik<CreateTeamDTO>({
        initialValues: {
            name: '',
            description: '',
            slug: '',
            parentId: null,
        },
        validateOnBlur: true,
        validate: withJoiSchema<CreateTeamDTO>(createTeamValidator),
        onSubmit: async (values) => {
            setLoading(true);
            try {
                const { id } = await api.teams.create(values);
                if (id) {
                    replace({
                        name: RouteNames.OWNERSHIP_TEAM_DETAILS,
                        params: { teamId: id },
                    });
                }
                success('Zespół został utworzony pomyślnie!');
            } catch (err) {
                error(toMessagesList(getErrorMessages(err)));
            }
            finally {
                setLoading(false);
            }
        },
    });

    function handleNameChange(evt: React.ChangeEvent<HTMLInputElement>) {
        form.handleChange(evt);

        if (!slugTouched.current) {
            form.setFieldValue('slug', kebabCase(deburr(evt.target.value)), false);
        }
    }

    function handleSlugChange(evt: React.ChangeEvent<HTMLInputElement>) {
        form.handleChange(evt);
        slugTouched.current = true;
    }

    return (
        <Page
            primaryActions={
                <SuccessButton
                    startIcon={mdiFloppy}
                    text="Utwórz zespół"
                    onClick={form.submitForm}
                />
            }
            secondaryActions={
                <SecondaryButton
                    startIcon={mdiArrowLeft}
                    text="Anuluj"
                    to={{ name: RouteNames.OWNERSHIP_TEAMS }}
                />
            }
        >
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <TextField
                        value={form.values.name}
                        label="Nazwa zespołu"
                        size="small"
                        fullWidth
                        name="name"
                        onChange={handleNameChange}
                        error={!!form.errors.name}
                        helperText={form.errors.name}
                        disabled={loading}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        value={form.values.slug}
                        label="Prosty identyfikator"
                        size="small"
                        fullWidth
                        name="slug"
                        onChange={handleSlugChange}
                        error={!!form.errors.slug}
                        helperText={form.errors.slug}
                        disabled={loading}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        value={form.values.description}
                        label="Opis"
                        size="small"
                        name="description"
                        fullWidth
                        multiline
                        minRows={4}
                        maxRows={6}
                        onChange={form.handleChange}
                        error={!!form.errors.description}
                        helperText={form.errors.description}
                        disabled={loading}
                    />
                </Grid>
            </Grid>
        </Page>
    );
}
