import { AttributeValueType } from '@hnx-archivr/documents/attribute';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import IconButton from '@mui/material/IconButton';
import Icon from '@mdi/react';
import { mdiTrashCanOutline } from '@mdi/js';
import { TrueFalseSwitch } from './TrueFalseSwitch';
import type { DocumentAttributeDTO } from '@hnx-archivr/documents';
import { useAttributeNameSuggestion } from './use-attribute-name-suggestion';

export interface AttributeRowProps {
    attribute: DocumentAttributeDTO;
    onDeleteClick?: (attribute: DocumentAttributeDTO) => void;
    onChange: (newValue: DocumentAttributeDTO) => void;
    errors?: Partial<Record<keyof DocumentAttributeDTO, string>>;
    disabled?: boolean;
}

const labels: Record<AttributeValueType, string> = {
    STRING: 'Tekst',
    NUMBER: 'Liczba',
    BOOLEAN: 'Wartość logiczna',
    DATE: 'Data',
    TIME: 'Godzina',
};

export function AttributeRow({
    attribute,
    onDeleteClick,
    onChange,
    errors = {},
    disabled = false,
}: AttributeRowProps) {
    const { search, suggestions, loading } = useAttributeNameSuggestion({
        debounceMs: 500,
    });

    function onAttributeNameChange(newValue: DocumentAttributeDTO | string | null) {
        if (!newValue) {
            onChange({
                ...attribute,
                name: '',
            });
        } else if (typeof newValue === 'string') {
            onChange({
                ...attribute,
                name: newValue,
            });
        } else {
            onChange({
                name: newValue.name,
                valueType: newValue.valueType,
                value: attribute.value,
            });
            onValueTypeUpdate(newValue.valueType);
        }
    }

    function onValueUpdate(newValue: string | number | boolean) {
        onChange({
            ...attribute,
            value: newValue,
        });
    }

    function onValueTypeUpdate(valueType: AttributeValueType | null) {
        let newValue: string | number | boolean;

        if (valueType === attribute.valueType) {
            newValue = attribute.value;
        } else {
            switch (valueType) {
                case 'NUMBER':
                    newValue = 0;
                    break;
                case 'BOOLEAN':
                    newValue = false;
                    break;
                case 'STRING':
                case 'DATE':
                case 'TIME':
                default:
                    newValue = '';
                    break;
            }
        }

        onChange({
            ...attribute,
            valueType: valueType ?? 'STRING',
            value: newValue,
        });
    }

    function handleNameInputChange(_: React.SyntheticEvent, name: string) {
        onChange({
            ...attribute,
            name,
        });
        search(name);
    }

    return (
        <Grid container spacing={1} marginBottom={1.5}>
            <Grid item xs={12} sm={4} container flexDirection="row" alignItems="flex-start">
                <IconButton color="error" onClick={() => onDeleteClick?.(attribute)}>
                    <Icon size={1} path={mdiTrashCanOutline} />
                </IconButton>
                <Autocomplete
                    value={attribute}
                    onChange={(_, value) => onAttributeNameChange(value)}
                    onInputChange={handleNameInputChange}
                    getOptionKey={(option) => typeof option === 'string' ? option : option.name}
                    getOptionLabel={(option) => typeof option === 'string' ? option : option.name}
                    isOptionEqualToValue={(option, value) => option.name === value.name}
                    options={suggestions}
                    size="small"
                    freeSolo
                    clearOnBlur
                    selectOnFocus
                    loading={loading}
                    disabled={disabled}
                    sx={{
                        flexBasis: 1,
                        flexGrow: 1,
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            placeholder="Atrybut"
                            size="small"
                            type="text"
                            error={!!errors.name}
                            helperText={errors.name}
                            disabled={disabled}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <Autocomplete
                    value={attribute.valueType}
                    options={AttributeValueType}
                    renderInput={(params) => <TextField {...params} disabled={disabled} label="Typ wartości" />}
                    getOptionLabel={(option: AttributeValueType) => labels[option]}
                    onChange={(_, newValue) => onValueTypeUpdate(newValue)}
                    size="small"
                    fullWidth
                    disabled={disabled}
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                {attribute.valueType === 'STRING'
                    && <TextField
                        value={attribute.value}
                        onChange={(evt) => onValueUpdate(evt.target.value)}
                        type="string"
                        placeholder="Wartość"
                        size="small"
                        fullWidth
                        error={!!errors.value}
                        helperText={errors.value}
                        disabled={disabled}
                    />
                }
                {attribute.valueType === 'NUMBER'
                    && <TextField
                        value={attribute.value}
                        onChange={(evt) => onValueUpdate(evt.target.value)}
                        type="number"
                        placeholder="Wartość"
                        size="small"
                        fullWidth
                        error={!!errors.value}
                        helperText={errors.value}
                        disabled={disabled}
                    />
                }
                {attribute.valueType === 'DATE'
                    && <TextField
                        value={attribute.value}
                        onChange={(evt) => onValueUpdate(evt.target.value)}
                        type="date"
                        placeholder="Wartość"
                        size="small"
                        fullWidth
                        error={!!errors.value}
                        helperText={errors.value}
                        disabled={disabled}
                    />
                }
                {attribute.valueType === 'TIME'
                    && <TextField
                        value={attribute.value}
                        onChange={(evt) => onValueUpdate(evt.target.value)}
                        type="time"
                        placeholder="Wartość"
                        size="small"
                        fullWidth
                        error={!!errors.value}
                        helperText={errors.value}
                        disabled={disabled}
                    />
                }
                {attribute.valueType === 'BOOLEAN'
                    && <TrueFalseSwitch
                        value={attribute.value as boolean}
                        onChange={(newValue) => onValueUpdate(newValue)}
                        trueLabel="Prawda"
                        falseLabel="Fałsz"
                        disabled={disabled}
                    />
                }
            </Grid>
        </Grid>
    );
}
