import type { SidebarItem } from '@/layout';
import { mdiAccountMultiple, mdiCardAccountDetailsOutline } from '@mdi/js';
import { RouteNames } from './routes';

export const sidebar: SidebarItem[] = [
    {
        icon: mdiCardAccountDetailsOutline,
        label: 'Użytkownicy',
        route: { name: RouteNames.OWNERSHIP_USERS },
        group: 'Użytkownicy i zespoły',
        permissions: ['ownership.users.browse'],
    },
    {
        icon: mdiAccountMultiple,
        label: 'Zespoły',
        route: { name: RouteNames.OWNERSHIP_TEAMS },
        group: 'Użytkownicy i zespoły',
        permissions: ['ownership.teams.browse'],
    },
];
