import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Icon from '@mdi/react';
import { mdiMenu } from '@mdi/js';
import { common } from '@mui/material/colors';
import { APP_BAR_ID } from '../../constants';

export interface AppHeaderProps {
    title?: string;
    onMenuClick?: () => void;
}

export function AppHeader({
    onMenuClick,
    title = 'HNX Archivr',
}: AppHeaderProps) {
    return (
        <AppBar position="sticky" sx={{ zIndex: 1500 }} id={APP_BAR_ID}>
            <Toolbar>
                {onMenuClick
                    && <IconButton onClick={onMenuClick} >
                        <Icon path={mdiMenu} size={1} color={common.white} />
                    </IconButton>
                }
                <Typography
                    sx={{
                        flexGrow: 1,
                        paddingLeft: 2,
                    }}
                    variant="h6"
                    fontWeight="bold"
                    noWrap
                >
                    {title}
                </Typography>
            </Toolbar>
        </AppBar>
    );
}
