import type { DialogProps } from '@mui/material/Dialog';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import type { ButtonProps } from '@mui/material/Button';
import Button from '@mui/material/Button';
import { useEffect, useState } from 'react';
import { dialogs, useConfirmation } from './use-confirmation';
import { useSignals } from '@preact/signals-react/runtime';

export interface ConfirmationDialogProps {
    id: string;
    title?: string;
    message: string | React.JSX.Element;
    confirmButtonLabel?: string;
    confirmButtonProps?: Omit<ButtonProps, 'onClick'>;
    cancelButtonLabel?: string;
    cancelButtonProps?: Omit<ButtonProps, 'onClick'>;
    dialogProps?: Omit<DialogProps, 'open'>;
    autoCloseOnAction?: boolean;
    onConfirm?: () => void;
    onCancel?: () => void;
}

export function ConfirmationDialog({
    title,
    message,
    confirmButtonLabel = 'OK',
    confirmButtonProps = {},
    cancelButtonLabel = 'Anuluj',
    cancelButtonProps = {},
    dialogProps = {},
    autoCloseOnAction = false,
    id,
    onConfirm,
    onCancel,

}: ConfirmationDialogProps) {
    const { hide } = useConfirmation(id);
    useSignals();
    const [opened, setOpened] = useState(false);

    useEffect(() => {
        setOpened(dialogs.value[id] ?? false);
    }, [id, dialogs.value]);

    return (
        <Dialog
            {...dialogProps}
            open={opened}
            id={id}
            sx={{ zIndex: 1501, ...(dialogProps.sx ?? {}) }}
        >
            {title && <DialogTitle>{title}</DialogTitle>}
            <DialogContent>
                <DialogContentText>
                    {message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    {...cancelButtonProps}
                    onClick={() => {
                        onCancel?.();
                        if (autoCloseOnAction) {
                            hide();
                        }
                    }}
                >
                    {cancelButtonLabel}
                </Button>
                <Button
                    autoFocus
                    {...confirmButtonProps}
                    onClick={() => {
                        onConfirm?.();
                        if (autoCloseOnAction) {
                            hide();
                        }
                    }}
                >
                    {confirmButtonLabel}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
