export default {
    documents: {
        edit: 'documents.edit',
        view: 'documents.view',
    },
    ownership: {
        teams: {
            create: 'ownership.teams.create',
            browse: 'ownership.teams.browse',
        },
        users: {
            create: 'ownership.users.create',
            browse: 'ownership.users.browse',
        },
    },
} as const;

export const PermissionsList = [
    'documents.edit',
    'documents.view',
    'ownership.teams.create',
    'ownership.teams.browse',
    'ownership.users.create',
    'ownership.users.browse',
] as const;

export type Permission = typeof PermissionsList[number];
