import type { SidebarItem } from '@/layout';
import { mdiViewDashboardOutline } from '@mdi/js';
import { RouteNames } from './route-names';

export const sidebar: SidebarItem[] = [
    {
        icon: mdiViewDashboardOutline,
        label: 'Strona główna',
        route: { name: RouteNames.DASHBOARD },
    },
];
