import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Icon from '@mdi/react';
import { mdiMagnify } from '@mdi/js';

export interface DataTableFilterInputProps {
    value: string;
    onChange: (newValue: string) => void;
    placeholder?: string;
}

export function DataTableFilterInput({
    value,
    onChange,
    placeholder = 'Wyszukaj',
}: DataTableFilterInputProps) {
    function handleKeyboardInteraction(evt: React.KeyboardEvent) {
        if (evt.key === 'Escape') {
            onChange('');
        }
    }

    return (
        <Grid container marginBottom={1.5} justifyContent="flex-end">
            <Grid item xs={12} md={6} lg={4} xl={3}>
                <TextField
                    value={value}
                    onChange={(evt) => onChange(evt.target.value)}
                    size="small"
                    placeholder={placeholder}
                    variant="outlined"
                    fullWidth
                    InputProps={{
                        startAdornment: <Icon size={1} path={mdiMagnify} />,
                    }}
                    onKeyUp={handleKeyboardInteraction}
                />
            </Grid>
        </Grid>
    );
}
