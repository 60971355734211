import { DashboardPage } from './pages/DashboardPage';
import type { RouteDefinition} from '@/router';
import { RouteMetaProperty } from '@/router';
import { RouteNames } from './route-names';

export const routes: RouteDefinition[] = [
    {
        name: RouteNames.DASHBOARD,
        path: '/',
        Component: DashboardPage,
        meta: {
            [RouteMetaProperty.PAGE_TITLE]: 'Strona główna',
        },
    },
];
