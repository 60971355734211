import type { AxiosResponse } from 'axios';
import axios from 'axios';
import { isApiResponse } from './type-guards';
import type { ApiResponse } from './types';
import config from '@/config';

export const $http = axios.create({
    baseURL: config.apiUrl,
    withCredentials: true,
});

$http.interceptors.response.use(
    (response: AxiosResponse<ApiResponse>) => {
        if (isApiResponse(response.data)) {
            return response.data.payload as any;
        }
        if (response.data !== null || response.data !== undefined) {
            return response.data;
        }
        return response;
    },
);

declare module 'axios' {
    export interface AxiosInstance {
        request<T = unknown> (config: AxiosRequestConfig): Promise<T>;
        get<T = unknown>(url: string, config?: AxiosRequestConfig): Promise<T>;
        delete<T = unknown>(url: string, config?: AxiosRequestConfig): Promise<T>;
        head<T = unknown>(url: string, config?: AxiosRequestConfig): Promise<T>;
        post<T = unknown>(url: string, data?: unknown, config?: AxiosRequestConfig): Promise<T>;
        put<T = unknown>(url: string, data?: unknown, config?: AxiosRequestConfig): Promise<T>;
        patch<T = unknown>(url: string, data?: unknown, config?: AxiosRequestConfig): Promise<T>;
    }
}
