import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import type { CardProps } from '@mui/material/Card';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Icon from '@mdi/react';
import { mdiTrashCanOutline } from '@mdi/js';
import { format } from 'bytes';
import type { MimeType } from '../formatting';
import { MimeDisplayNames, MimeIcons } from '../formatting';
import { blueGrey } from '@mui/material/colors';
import { IconButton } from '@/modules/ui/button';

export interface FileSelectorCardProps {
    file: File;
    onDeleteClick: (file: File) => void;
    sx?: CardProps['sx'];
    showMimeType?: boolean;
    disabled?: boolean;
}

export function FileSelectorCard({
    file,
    sx = {},
    showMimeType = false,
    disabled = false,
    onDeleteClick,
}: FileSelectorCardProps) {
    const iconPath = MimeIcons[file.type as MimeType] ?? MimeIcons.fallback;
    const humanReadableType = MimeDisplayNames[file.type as MimeType] ?? MimeDisplayNames.fallback;

    return (
        <Card
            sx={{
                display: 'flex',
                flexDirection: 'row',
                ...sx,
            }}
            variant="outlined"
        >
            <Box
                display="flex"
                paddingY={2}
                paddingLeft={2}
            >
                <Avatar
                    variant="rounded"
                    sx={{
                        width: 64,
                        height: 64,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        bgcolor: blueGrey[200],
                        marginTop: 1,
                    }}
                >
                    <Icon size={1.75} path={iconPath} />
                </Avatar>
            </Box>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
            }}>
                <CardContent sx={{ flex: '1 0 auto' }}>
                    <Typography component="div" variant="h6">
                        {file.name}
                    </Typography>
                    <Typography variant="subtitle1" color="text.secondary" component="div">
                        {humanReadableType} {showMimeType && <>({file.type})</>}
                    </Typography>
                    <Typography variant="body1" color="text.secondary" component="div">
                        {format(file.size)}
                    </Typography>
                </CardContent>
            </Box>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                paddingX: 0.5,
                paddingY: 1,
            }}>
                <IconButton
                    icon={mdiTrashCanOutline}
                    color="error"
                    onClick={() => onDeleteClick(file)}
                    disabled={disabled}
                />
            </Box>
        </Card>
    );
}
