type EnvName = 'development' | 'production';

interface Config {
    apiUrl: string;
}

const configs: Record<EnvName, Config> = {
    development: {
        apiUrl: '/api/v1',
    },
    production: {
        apiUrl: `https://${import.meta.env.VITE_API_DOMAIN}/v1`,
    },
};

const currentConfig = configs[import.meta.env.MODE as EnvName] ?? configs.development;
export default currentConfig;
