import { PrimaryButton } from '@/modules/ui/button';
import { Page } from '@/modules/ui/page';
import { mdiAccountMultiplePlusOutline } from '@mdi/js';
import { RouteNames } from '../routes';
import type { DataTableColumn } from '@/modules/ui/data-table';
import { DataTable } from '@/modules/ui/data-table';
import { useState } from 'react';
import { api } from '../api';
import { useRouterNavigation } from '@/router';
import type { TeamListItemDTO } from '@hnx-archivr/ownership';
import { useMountEffect } from '@react-hookz/web';

export function TeamsList() {
    const [teams, setTeams] = useState<TeamListItemDTO[]>([]);
    const [loading, setLoading] = useState(false);
    const { push } = useRouterNavigation();

    const columns: DataTableColumn<TeamListItemDTO>[] = [
        {
            property: 'name',
            headerLabel: 'Nazwa',
        },
        {
            property: 'slug',
            headerLabel: 'Identyfikator',
        },
        {
            property: 'description',
            headerLabel: 'Opis',
        },
    ];

    function handleRowClick(row: TeamListItemDTO) {
        push({
            name: RouteNames.OWNERSHIP_TEAM_DETAILS,
            params: { teamId: row.id },
            data: row,
        });
    }

    function rowFilterFunction(row: TeamListItemDTO, searchText: string) {
        const regex = new RegExp(searchText, 'i');
        return regex.test(row.name ?? '')
            || regex.test(row.description ?? '');
    }

    useMountEffect(async () => {
        setLoading(true);
        try {
            const results = await api.teams.list();
            setTeams(results);
        } finally {
            setLoading(false);
        }
    });

    return (
        <Page
            primaryActions={
                <PrimaryButton
                    startIcon={mdiAccountMultiplePlusOutline}
                    text="Utwórz zespół"
                    to={{ name: RouteNames.CREATE_OWNERSHIP_TEAM }}
                />
            }
        >

            <DataTable
                columns={columns}
                rows={teams}
                loading={loading}
                rowHover
                filterable={rowFilterFunction}
                onRowClick={handleRowClick}
            />
        </Page>
    );
}
