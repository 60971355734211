import { SkeletonLoader } from '../../skeleton-loader';
import type { DataTableColumn } from '../types';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import type { DataTableFilterInputProps } from './DataTableFilterInput';
import { DataTableFilterInput } from './DataTableFilterInput';
import { useMemo, useState } from 'react';

export interface DataTableProps<Row = any> {
    columns: DataTableColumn<Row>[];
    rows: Row[];
    loading?: boolean;
    rowHover?: boolean;
    onRowClick?: (row: Row) => void;
    getRowKey?: (row: Row) => string;
    filterable?: ((row: Row, searchText: string) => boolean) | false;
    filterInputProps?: Partial<DataTableFilterInputProps>;
}

export function DataTable({
    columns,
    rows,
    loading = false,
    rowHover,
    onRowClick,
    getRowKey = (row) => row.id,
    filterable = false,
    filterInputProps = {},
}: DataTableProps) {
    const [filterQuery, setFilterText] = useState('');

    const rowsToDisplay = useMemo(() => {
        let results = rows;
        if (typeof filterable === 'function') {
            results = results.filter((row) => filterable(row, filterQuery));
        }
        return results;
    }, [
        rows,
        filterable,
        filterQuery,
    ]);

    return (
        <SkeletonLoader loading={loading} variant="table">
            {filterable
                && <DataTableFilterInput
                    {...filterInputProps}
                    value={filterQuery}
                    onChange={setFilterText}
                />
            }

            <TableContainer component={Paper}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            {
                                columns.map((column) => (
                                    <TableCell
                                        align={column.align}
                                        key={`header-cell-${column.property.toString()}`}
                                    >
                                        {column.headerLabel ?? column.property.toString()}
                                    </TableCell>
                                ))
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rowsToDisplay.map((row, index) => (
                            <TableRow
                                hover={rowHover}
                                onClick={() => onRowClick?.(row)}
                                key={getRowKey(row)}
                                sx={{
                                    '&:hover': {
                                        cursor: onRowClick && rowHover ? 'pointer' : 'initial',
                                    },
                                }}
                            >
                                {
                                    columns.map(({ align, property, CellComponent }, colIndex) => (
                                        <TableCell
                                            align={align}
                                            key={`row-cell-${property.toString()}-${getRowKey(row)}-${index}-${colIndex}`}
                                        >
                                            {CellComponent
                                                && <CellComponent property={property} row={row} value={row[property]} />
                                            }
                                            {!CellComponent
                                                && row[property]
                                            }
                                        </TableCell>
                                    ))
                                }
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </SkeletonLoader>
    );
}
