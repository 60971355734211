import { toDateString, toLocaleFormattedNumber } from '@/utils/formatters';
import type { AttributeValueType } from '@hnx-archivr/documents';
import { mdiCheckboxBlankOutline, mdiCheckboxMarkedOutline } from '@mdi/js';
import Icon from '@mdi/react';

export interface AttributeValuePreviewProps {
    value: string | number | boolean;
    valueType: AttributeValueType;
}

export function AttributeValuePreview({
    value,
    valueType,
}: AttributeValuePreviewProps) {
    let valueToDisplay: string | React.JSX.Element;

    switch (valueType) {
        case 'BOOLEAN':
            valueToDisplay = (
                <Icon
                    path={value ? mdiCheckboxMarkedOutline : mdiCheckboxBlankOutline}
                    size={1}
                />
            );
            break;
        case 'DATE':
            valueToDisplay = toDateString(value as string);
            break;
        case 'NUMBER':
            valueToDisplay = toLocaleFormattedNumber(value as number);
            break;
        case 'STRING':
        case 'TIME':
        default:
            valueToDisplay = `${value}`;
            break;
    }

    return valueToDisplay!;
}
