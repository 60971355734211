import Alert from '@mui/material/Alert';
import { IconButton } from '@/modules/ui/button';
import type { Toast } from '../types';
import { mdiClose } from '@mdi/js';
import { common } from '@mui/material/colors';

export interface ToastCardProps {
    toast: Toast,
    onDismissClick?: (toast: Toast) => void;
}

export function ToastCard({
    toast,
    onDismissClick,
}: ToastCardProps) {
    return (
        <Alert
            severity={toast.type}
            variant="filled"
            elevation={1}
            action={
                <IconButton
                    onClick={() => onDismissClick?.(toast)}
                    icon={mdiClose}
                    sx={{ color: common.white }}
                    size="small"
                />
            }>
            {toast.message}
        </Alert>
    );
}
