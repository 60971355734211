import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import { PermissionsList } from '@hnx-archivr/permissions/lib';

export interface UserPermissionsProps {
    label?: string;
    value?: string[];
    onChange?: (permissions: string[]) => void;
}

export function UserPermissions({
    label = '',
    value = [],
    onChange,
}: UserPermissionsProps) {
    function handleCheckboxStateChange(permission: string, checked: boolean) {
        if (!checked) {
            onChange?.(
                value.filter((p) => permission !== p),
            );
        } else {
            onChange?.([...value, permission]);
        }
    }

    return (
        <Card elevation={0}>
            <CardContent>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    marginBottom={2}
                >
                    <Typography gutterBottom variant="h5" component="div">
                        {label}
                    </Typography>
                </Box>

                <Box>
                    <FormGroup>
                        {
                            PermissionsList.map((permission) => (
                                <FormControlLabel
                                    key={permission}
                                    control={
                                        <Checkbox
                                            checked={value.includes(permission)}
                                            onChange={(_, checked) => handleCheckboxStateChange(permission, checked)}
                                        />
                                    }
                                    label={permission}
                                />
                            ))
                        }
                    </FormGroup>
                </Box>
            </CardContent>
        </Card>
    );
}
