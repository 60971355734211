import type { Keyword } from '@hnx-archivr/documents';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';

export interface ItemKeywordsProps {
    keywords: Keyword[];
    itemId: string;
}

export function ItemKeywords({
    keywords,
    itemId,
}: ItemKeywordsProps) {
    return (
        <Box
            display="flex"
            flexDirection="row"
            alignItems="flex-start"
            justifyContent="flex-start"
            gap={1}
            marginY={0.5}
        >
            {
                keywords.map((keyword) => (
                    <Chip
                        variant="filled"
                        key={`${itemId}-${keyword}`}
                        label={keyword}
                        size="small"
                    />
                ))
            }
        </Box>
    );
}
