import { useEffect, useState } from 'react';
import { useAuth } from '@/modules/auth';
import { RouteMetaProperty, useCurrentRoute, useRouterNavigation } from '@/router';
import { AppHeader, DEFAULT_SIDEBAR_WIDTH, Sidebar } from './components';
import { Outlet, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import { Loader } from '@/modules/ui/loader';
import { theme } from '@/theme';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useMountEffect } from '@react-hookz/web';
import { useWatchRouteChange } from '@/router/hooks';

export function Layout() {
    const { name: currentRouteName, query: currentQueryParams } = useCurrentRoute();
    const location = useLocation();
    const [drawerOpened, setDrawerOpened] = useState(true);
    const [headerTitle, setHeaderTitle] = useState('HNX Archivr');
    const { isLoggedIn, loading, fetchCurrentUser } = useAuth();
    const { replace, navigate } = useRouterNavigation();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

    function toggleDrawer() {
        setDrawerOpened((oldValue) => !oldValue);
    }

    useMountEffect(async () => {
        await fetchCurrentUser();
    });

    useWatchRouteChange((route) => {
        const title = route?.meta?.[RouteMetaProperty.PAGE_TITLE] ?? 'HNX Archivr';
        setHeaderTitle(title);
        document.title = title !== 'HNX Archivr'
            ? `HNX Archivr | ${title}`
            : title;
    });

    useEffect(() => {
        if (isLoggedIn) {
            const redirectTo = currentQueryParams?.referer;
            if (currentRouteName === 'LOGIN') {
                redirectTo && redirectTo !== '/login' // TODO: Update - użyj toRoute()
                    ? navigate(redirectTo, { replace: true })
                    : replace({ name: 'DASHBOARD' });
            }
        } else {
            replace({
                name: 'LOGIN',
                query: { referer: location.pathname },
            });
        }
    }, [isLoggedIn]);

    useEffect(() => {
        if (isDesktop) {
            setDrawerOpened(true);
        }
    }, [isDesktop]);

    return (
        <>
            {isLoggedIn
                && <>
                    <AppHeader
                        onMenuClick={isDesktop ? undefined : toggleDrawer}
                        title={headerTitle}
                    />
                    <Sidebar
                        opened={drawerOpened}
                        variant={isDesktop ? 'permanent' : 'temporary'}
                        onBackdropClick={() => setDrawerOpened(false)}
                        onSidebarItemClick={() => !isDesktop && setDrawerOpened(false)}
                    />
                </>
            }

            <Box
                component="main"
                sx={{
                    marginLeft: drawerOpened && isLoggedIn && isDesktop ? `${DEFAULT_SIDEBAR_WIDTH}px` : 0,
                    transition: 'margin 225ms cubic-bezier(0, 0, 0.2, 1)',
                }}
            >
                <Loader loading={loading} />
                {!loading && <Outlet />}

            </Box>
        </>
    );
}
