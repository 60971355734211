import { signal } from '@preact/signals-react';
import type { Toast, ToastId, ToastOptions, ToastType} from './types';
import { isToast } from './types';
import { DEFAULT_TOAST_OPTIONS } from './defaults';

const getToastId = (() => {
    let lastId = -1;
    return () => `toast-${++lastId}`;
})();

const toastTimeoutIds: Record<ToastId, NodeJS.Timeout> = {};
export const toasts = signal<Toast[]>([]);

export function addToast(
    type: ToastType,
    message: string | React.JSX.Element,
    options: ToastOptions = {},
): Toast {
    const currentToastOptions = {
        ...DEFAULT_TOAST_OPTIONS,
        ...options,
    };
    const toast = {
        id: getToastId(),
        message,
        type,
    };

    if (currentToastOptions.autoDismiss) {
        toastTimeoutIds[toast.id] = setTimeout(() => {
            dismiss(toast);
            delete toastTimeoutIds[toast.id];
        }, currentToastOptions.autoDismiss);
    }

    toasts.value = [...toasts.value, toast];

    return toast;
}

export function dismiss(toast: Toast | ToastId) {
    const toastId = isToast(toast) ? toast.id : toast;

    toasts.value = toasts.value.filter(({ id }) => id !== toastId);

    clearTimeout(toastTimeoutIds[toastId]);
    delete toastTimeoutIds[toastId];
}

export function dismissAll() {
    toasts.value = [];

    Object.entries(toastTimeoutIds).forEach(([toastId, timeoutId]) => {
        clearTimeout(timeoutId);
        delete toastTimeoutIds[toastId];
    });
}
