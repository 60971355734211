import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import type { DocumentPermissionDTO, DocumentPermissionLevel } from '@hnx-archivr/documents/permission';
import { AddedPermissionRow } from './AddedPermissionRow';
import { NewPermissionSection } from './NewPermissionSection';

export interface PermissionsPickerProps {
    value: DocumentPermissionDTO[];
    onChange: (permissions: DocumentPermissionDTO[]) => void;
    disabled?: boolean;
}

export function PermissionsPicker({
    value,
    onChange,
    disabled = false,
}: PermissionsPickerProps) {
    const addedIds = value.map(({ teamId, userId }) => userId ?? teamId ?? '').filter(Boolean);

    function updatePermission(newLevel: DocumentPermissionLevel, index: number) {
        Object.assign(value[index], { level: newLevel });
        onChange(value);
    }

    function removePermission(indexToRemove: number) {
        if (value[indexToRemove]?.level === 'OWNER') {
            return;
        }
        const result = value.filter((_, index) => index !== indexToRemove);
        onChange(result);
    }

    return (
        <Card elevation={0}>
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                    Uprawnienia
                </Typography>

                {value.map((permission, index) => (
                    <AddedPermissionRow
                        key={index}
                        permission={permission}
                        onLevelChange={(newLevel) => updatePermission(newLevel, index)}
                        onDeleteClick={() => removePermission(index)}
                        disabled={disabled}
                    />
                ))}

                <NewPermissionSection
                    onPermissionAdd={(permission) => {
                        onChange([...value, permission]);
                    }}
                    disableIds={addedIds}
                    disabled={disabled}
                />
            </CardContent>
        </Card>
    );
}
