import { useState } from 'react';
import type { OwnershipSearchResultItem} from '../../api';
import { api } from '../../api';
import { getErrorMessages } from '@/core';
import debounce from 'lodash/debounce';

const cache = new Map<string, OwnershipSearchResultItem[]>();

export function clearCache() {
    cache.clear();
}

export interface PermissionSuggestionOptions {
    debounceMs?: number;
    initialQuery?: string;
}

export function usePermissionSuggestions(options: PermissionSuggestionOptions = {}) {
    const { debounceMs, initialQuery } = options;
    const ucInitialQuery = initialQuery?.toUpperCase();
    const [loading, setLoading] = useState(false);
    const [suggestions, setSuggestions] = useState<OwnershipSearchResultItem[]>(ucInitialQuery ? cache.get(ucInitialQuery) ?? [] : []);
    const [errors, setErrors] = useState<string[]>([]);

    async function _search(text: string) {
        const ucText = text.toUpperCase();
        if (!ucText.length) {
            return;
        }

        const fromCache = cache.get(ucText);
        if (fromCache) {
            setSuggestions(fromCache);
            return fromCache;
        }

        setLoading(true);
        try {
            const response = await api.ownership.search(ucText);
            cache.set(ucText, response);
            setSuggestions(response);
            return response;
        } catch (err) {
            setErrors(getErrorMessages(err));
            return [];
        } finally {
            setLoading(false);
        }
    }

    const search = typeof debounceMs === 'number'
        ? debounce(_search, debounceMs)
        : _search;

    return {
        loading,
        suggestions,
        errors,
        clearCache,
        search,
    };
}
