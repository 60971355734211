import { TransitionGroup } from 'react-transition-group';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import { ToastCard } from './ToastCard';
import { dismiss, toasts } from '../store';
import { useSignalEffect  } from '@preact/signals-react';
import { useState } from 'react';
import type { Toast } from '../types';
import { useAppBarHeight } from '@/layout';

const DEFAULT_TOAST_WIDTH = 400;

export function ToastsRenderer() {
    const [internalToasts, setInternalToasts] = useState<Toast[]>([]);
    const height = useAppBarHeight();

    useSignalEffect(() => {
        setInternalToasts(toasts.value);
    });

    return (
        <Box
            position="fixed"
            zIndex={9999}
            width={DEFAULT_TOAST_WIDTH}
            top={height + 10}
            left="50%"
            sx={{ translate: -DEFAULT_TOAST_WIDTH / 2 }}
        >
            <TransitionGroup>
                {internalToasts.map((toast) => (
                    <Collapse key={toast.id} sx={{ marginBottom: 1.25 }}>
                        <ToastCard
                            toast={toast}
                            onDismissClick={() => dismiss(toast.id)}
                        />
                    </Collapse>
                ))}
            </TransitionGroup>
        </Box>
    );
}
