
export function toDateTimeString(date: string | Date | number) {
    return new Intl.DateTimeFormat('pl', {
        dateStyle: 'short',
        timeStyle: 'short',
    }).format(new Date(date));
}

export function toDateString(date: string | Date | number) {
    return new Intl.DateTimeFormat('pl', {
        dateStyle: 'short',
    }).format(new Date(date));
}

export function toTimeString(date: string | Date | number) {
    return new Intl.DateTimeFormat('pl', {
        timeStyle: 'short',
    }).format(new Date(date));
}
