import { memo } from 'react';
import { TableSkeleton } from './TableSkeleton';

const PredefinedSkeletons = {
    table: memo(TableSkeleton),
} as const;

export default PredefinedSkeletons;

export type SkeletonVariant = keyof typeof PredefinedSkeletons;
