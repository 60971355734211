import Icon from '@mdi/react';
import type { TypographyProps } from '@mui/material/Typography';
import Typography from '@mui/material/Typography';
import type { PaperProps } from '@mui/material/Paper';
import Paper from '@mui/material/Paper';
import { grey } from '@mui/material/colors';
import React from 'react';

export interface PlaceholderProps extends PaperProps {
    icon?: string;
    iconSize?: number;
    text?: string;
    textVariant?: TypographyProps['variant'];
    children?: React.JSX.Element | React.JSX.Element[];
}

export function Placeholder({
    icon,
    iconSize = 2.5,
    textVariant = 'body1',
    text,
    children,
    color = grey[700],
    sx = {},
    ...rest
}: PlaceholderProps) {
    return (
        <Paper
            {...rest}
            variant="elevation"
            elevation={0}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                paddingX: 3,
                paddingY: 2,
                backgroundColor: grey[200],
                ...sx,
            }}
        >
            {icon
                && <Icon
                    size={iconSize}
                    path={icon}
                    color={color}
                />
            }
            {text
                && <Typography variant={textVariant} color={color}>
                    {text}
                </Typography>
            }
            {children}
        </Paper>
    );
}
