import type React from 'react';

export const ToastType = ['success', 'error', 'warning', 'info'] as const;
export type ToastType = typeof ToastType[number];

export interface Toast {
    id: ToastId;
    type: ToastType;
    message: string | React.JSX.Element;

}

export type ToastId = string;

export interface ToastOptions {
    autoDismiss?: number | null;
}

export function isToast(value: unknown): value is Toast {
    return typeof value === 'object'
        && value !== null
        && 'id' in value
        && typeof value.id === 'string'
        && 'message' in value
        && typeof value.message === 'string'
        && 'type' in value
        && typeof value.type === 'string'
        && (
            value.type === 'success'
            || value.type === 'error'
            || value.type === 'info'
            || value.type === 'warning'
        );
}
