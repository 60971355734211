import Box from '@mui/material/Box';
import type { PropsWithChildren } from 'react';
import React from 'react';
import { PageActions } from '../page-actions';
import { useAppBarHeight } from '@/layout';
import type { SxProps } from '@mui/material';

export type PageProps = PropsWithChildren<{
    noPadding?: boolean;
    sx?: SxProps;
    primaryActions?: React.JSX.Element | React.JSX.Element[] | string;
    secondaryActions?: React.JSX.Element | React.JSX.Element[] | string;
}>;

export function Page({
    noPadding = false,
    primaryActions,
    secondaryActions,
    sx = {},
    children,
}: PageProps) {
    const appBarHeight = useAppBarHeight();
    const hasActions = !!primaryActions || !!secondaryActions;

    return (
        <Box>
            {hasActions
                && <PageActions
                    primary={primaryActions}
                    secondary={secondaryActions}
                    sx={{
                        position: 'sticky',
                        top: appBarHeight,
                        paddingTop: 2,
                        paddingX: 2,
                    }}
                />
            }
            <Box
                sx={{
                    padding: noPadding ? 0 : 2,
                    ...sx,
                }}
            >
                {children}
            </Box>
        </Box>
    );
}
