import { createBrowserRouter } from 'react-router-dom';
import { routes } from './routes';
import { Layout } from '@/layout';

export function createRouter() {
    return createBrowserRouter([
        {
            path: '/',
            element: <Layout />,
            children: routes,
        },
    ]);
}
