import Icon from '@mdi/react';
import type { IconButtonProps as MuiIconButtonProps } from '@mui/material/IconButton';
import MuiIconButton from '@mui/material/IconButton';

export type IconButtonProps = Omit<MuiIconButtonProps, 'children'> & {
    iconSize?: number;
    icon: string;
};

export function IconButton(props: IconButtonProps) {
    const muiIconButtonProps = {
        ...props,
        iconSize: undefined,
        icon: undefined,
    };

    return (
        <MuiIconButton {...muiIconButtonProps}>
            <Icon size={props.iconSize ?? 1} path={props.icon} />
        </MuiIconButton>
    );
}
