import { Page } from '@/modules/ui/page';
import { useCurrentRoute, useRouterNavigation } from '@/router';
import { useState } from 'react';
import { api } from '../api';
import { toMessagesList, useToasts } from '@/modules/toasts';
import { getErrorMessages } from '@/core';
import type { DocumentDTO } from '@hnx-archivr/documents';
import { DangerButton, PrimaryButton, SecondaryButton } from '@/modules/ui/button';
import { mdiArrowLeft, mdiPencilOutline, mdiTrashCanOutline } from '@mdi/js';
import { RouteNames } from '../routes';
import { useAuth } from '@/modules/auth';
import {
    AttributesList,
    AttributesListSkeleton,
    BasicData,
    BasicDataSkeleton,
    PermissionsList,
    PermissionsListSkeleton,
} from '../components/document-details-preview';
import Grid from '@mui/material/Grid';
import { FilesList, FilesListSkeleton } from '../components/files/files-list';
import { SkeletonLoader } from '@/modules/ui/skeleton-loader';
import { useConfirmation } from '@/modules/ui/confirmation-dialog';
import { ConfirmationDialog } from '@/modules/ui/confirmation-dialog';
import { useMountEffect } from '@react-hookz/web';

export function DocumentDetailsPage() {
    const route = useCurrentRoute();
    const router = useRouterNavigation();
    const toasts = useToasts();
    const { currentUser } = useAuth();
    const { show: showDeleteConfirmation } = useConfirmation('delete-document');

    const [loading, setLoading] = useState(false);
    const [details, setDetails] = useState<DocumentDTO>({
        id: '',
        name: '',
        description: '',
        deleted: false,
        comments: [],
        keywords: [],
        attributes: [],
        permissions: [],
        files: [],
        createdAt: '',
        updatedAt: '',
    });

    const shouldShowEditButton = (() => {
        if (loading) {
            return false;
        }

        const userTeams = currentUser?.teams?.map(({ id }) => id) ?? [];
        return details.permissions.some(({ userId, teamId, level }) => {
            if (level === 'READ') {
                return false;
            }
            if (userId) {
                return currentUser?.id === userId;
            }
            if (teamId) {
                return userTeams.includes(teamId);
            }
            return false;
        });
    })();

    async function deleteDocument() {
        setLoading(true);
        try {
            const result = await api.remove(details.id);
            if (result) {
                toasts.success('Dokument został przeniesiony do kosza!');
                router.push({ name: RouteNames.DOCUMENTS_HOME });
            }
        } catch (err) {
            toasts.error(toMessagesList(getErrorMessages(err)));
        } finally {
            setLoading(true);
        }
    }

    useMountEffect(async () => {
        if (!route.params?.documentId) {
            toasts.error('Nie można pobrać danych dokumentu: nieprawidłowy identyfikator');
            return;
        }

        setLoading(true);
        try {
            const payload = await api.details(route.params.documentId);
            setDetails(payload);
        } catch (err) {
            toasts.error(toMessagesList(getErrorMessages(err)));
        } finally {
            setLoading(false);
        }
    });

    return (
        <Page
            primaryActions={shouldShowEditButton
                ? <>
                    <DangerButton
                        startIcon={mdiTrashCanOutline}
                        text="Usuń"
                        onClick={showDeleteConfirmation}
                    />
                    <PrimaryButton
                        startIcon={mdiPencilOutline}
                        text="Edytuj"
                        to={{
                            name: RouteNames.EDIT_DOCUMENT,
                            params: route.params,
                            data: details as any,
                        }}
                    />
                </>
                : undefined
            }
            secondaryActions={
                <SecondaryButton
                    startIcon={mdiArrowLeft}
                    to={{ name: RouteNames.DOCUMENTS_HOME }}
                    text="Powrót"
                />
            }
        >
            <ConfirmationDialog
                id="delete-document"
                title="Usuwanie dokumentu"
                message="Czy chcesz przenieść ten dokument do kosza?"
                confirmButtonLabel="Usuń"
                autoCloseOnAction
                onConfirm={deleteDocument}
            />
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <SkeletonLoader
                        loading={loading}
                        variant={<BasicDataSkeleton />}
                    >
                        <BasicData data={details} />
                    </SkeletonLoader>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <SkeletonLoader
                        loading={loading}
                        variant={<AttributesListSkeleton />}
                    >
                        <AttributesList attributes={details.attributes} />
                    </SkeletonLoader>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <SkeletonLoader
                        loading={loading}
                        variant={<PermissionsListSkeleton />}
                    >
                        <PermissionsList permissions={details.permissions} />
                    </SkeletonLoader>
                </Grid>
                <Grid item xs={12}>
                    <SkeletonLoader
                        loading={loading}
                        variant={<FilesListSkeleton />}
                    >
                        <FilesList files={details.files} />
                    </SkeletonLoader>
                </Grid>
            </Grid>
        </Page>
    );
}
