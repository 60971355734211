import type { DocumentPermissionLevel } from '@hnx-archivr/documents/permission';

export const SelectablePermissionLevel = ['READ', 'WRITE'] as const;
export type SelectablePermissionLevel = typeof SelectablePermissionLevel[number];

export const LABELS: Record<DocumentPermissionLevel, string> = {
    OWNER: 'Właściciel',
    READ: 'Przeglądający',
    WRITE: 'Edytujący',
};
