import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';

export interface TableSkeletonProps {
    rows?: number;
    cols?: number;
    filter?: boolean;
    pagination?: boolean;
}

export function TableSkeleton({
    rows = 10,
    cols = 6,
    filter = true,
    pagination = true,
}: TableSkeletonProps) {
    const rowsToRender = Array(rows).fill(null);
    const colsToRender = Array(cols).fill(null);
    const lastRowIndex = rowsToRender.length - 1;

    return (
        <Box>
            {filter
            && <Grid container spacing={1} sx={{ marginBottom: 2 }} justifyContent="flex-end">
                <Grid item xs={4}>
                    <Skeleton variant="rounded" height="2rem" />
                </Grid>
            </Grid>
            }

            <Paper sx={{ padding: 1.5 }}>
                <Grid container spacing={1} sx={{ marginBottom: 2 }}>
                    {colsToRender.map((_, colIndex) => (
                        <Grid item xs={2} container justifyContent="center" key={`skeleton-col-header-${colIndex}`}>
                            <Skeleton variant="rounded" width="33%" height="1.25rem" />
                        </Grid>
                    ))}
                </Grid>

                {rowsToRender.map((_, rowIndex) => (
                    <Grid container spacing={1} key={`skeleton-row-${rowIndex}`}>
                        {colsToRender.map((_, colIndex) => (
                            <Grid item xs={2} container justifyContent="center" key={`skeleton-row-${rowIndex}-${colIndex}`}>
                                <Skeleton
                                    variant="rounded"
                                    width="66%"
                                    height="0.5rem"
                                    sx={{
                                        marginTop: 1.5,
                                        marginBottom: 1.5,
                                    }}
                                />
                            </Grid>
                        ))}
                        {(rowIndex !== lastRowIndex)
                        && <Grid item xs={12} sx={{ paddingBottom: 1 }}>
                            <Skeleton variant="rounded" height="2px" />
                        </Grid>}
                    </Grid>
                ))}
            </Paper>

            {pagination
            && <Grid container spacing={1} sx={{ marginTop: 1 }} justifyContent="flex-end">
                <Grid item xs={3} container justifyContent="flex-end" alignItems="center" gap={1}>
                    <Skeleton variant="rounded" width="50%" height="1rem" />
                    <Skeleton variant="circular" width="2rem" height="2rem" />
                    <Skeleton variant="circular" width="2rem" height="2rem" />
                </Grid>
            </Grid>
            }
        </Box>
    );
}
