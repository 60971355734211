import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

export function BasicDataSkeleton() {
    return (
        <>
            <Box>
                <Skeleton
                    height={42}
                    variant="rounded"
                    width="50%"
                    sx={{ marginBottom: 2, minWidth: 350 }}
                />
            </Box>
            <Box marginBottom={2}>
                <Skeleton variant="text" width="90%" />
                <Skeleton variant="text" width="80%" />
            </Box>
            <Box display="flex" flexDirection="row" gap={1}>
                <Skeleton height={32} width={90} variant="rounded" sx={{ borderRadius: 4 }} />
                <Skeleton height={32} width={100} variant="rounded" sx={{ borderRadius: 4 }} />
                <Skeleton height={32} width={75} variant="rounded" sx={{ borderRadius: 4 }} />
            </Box>
        </>
    );
}
