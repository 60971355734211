import { $http } from '@/core';
import type { CreateTeamDTO, CreateUserDTO, TeamDTO, TeamListItemDTO, UpdateTeamDTO, UpdateUserDTO, UserDTO, UserListItemDTO } from '@hnx-archivr/ownership';

export default {
    teams: {
        details: (teamId: string) => $http.get<TeamDTO>(`/accounts/management/teams/${teamId}`),
        create: (data: CreateTeamDTO) => $http.post<TeamDTO>('/accounts/management/teams/create', data),
        update: (teamId: string, data: UpdateTeamDTO) => $http.put<TeamDTO>(`/accounts/management/teams/${teamId}`, data),
        list: () => $http.get<TeamListItemDTO[]>('/accounts/management/teams'),
    },
    users: {
        list: () => $http.get<UserListItemDTO[]>('/accounts/management/users'),
        details: (userId: string) => $http.get<UserDTO>(`/accounts/management/users/${userId}`),
        create: (data: CreateUserDTO) => $http.post<UserDTO>('/accounts/management/users/create', data),
        update: (userId: string, data: UpdateUserDTO) => $http.put<UserDTO>(`/accounts/management/users/${userId}`, data),
    },
    ownershipQuery: {
        teamsList: () => $http.get<TeamListItemDTO[]>('/ownership/query/teams-list'),
    },
};
