import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { mdiUploadOutline, mdiFilePlusOutline } from '@mdi/js';
import React, { useRef, useState } from 'react';
import { green, grey } from '@mui/material/colors';
import { Placeholder } from '@/modules/ui/placeholder';
import { RouterLink } from '@/router';
import { RouteNames } from '../../routes';
import { PrimaryButton } from '@/modules/ui/button';
import { styled } from '@mui/material/styles';

const FileInput = styled('input')({
    width: 1,
    display: 'none',
});

export interface InitialFileUploadDialogProps {
    opened: boolean;
    onFilesSelected: (files: File[]) => void;
}

export function InitialFileUploadDialog({
    opened,
    onFilesSelected,
}: InitialFileUploadDialogProps) {
    const [dragging, setDragging] = useState(false);
    const fileInputRef = useRef<HTMLInputElement>(null);

    function onDrop(evt: React.DragEvent) {
        evt.preventDefault();
        const files: File[] = [];

        if (evt.dataTransfer.items) {
            [...evt.dataTransfer.items].forEach((item) => {
                if (item.kind === 'file') {
                    const file = item.getAsFile();
                    if (file) {
                        files.push(file);
                    }
                }
            });
        } else {
            [...evt.dataTransfer.files].forEach((file) => {
                files.push(file);
            });
        }

        setDragging(false);
        onFilesSelected(files);
    }

    function onDragOver(evt: React.DragEvent) {
        evt.preventDefault();
    }

    function onDragEnter(evt: React.DragEvent) {
        evt.preventDefault();
        setDragging(true);
    }

    function onDragExit(evt: React.DragEvent) {
        evt.preventDefault();
        setDragging(false);
    }

    function handleInputChangeEvent(evt: React.ChangeEvent<HTMLInputElement>) {
        if (!evt.target.files) {
            return;
        }
        const files = [...evt.target.files];
        if (files.length) {
            onFilesSelected(files);
        }
    }

    return (
        <Dialog
            open={opened}
            fullWidth
            sx={{
                zIndex: 2000,
            }}
        >
            <DialogTitle>
                Nowy dokument
                <Typography variant="body2">
                    Wybierz pliki, które chcesz przesłać i dodać do dokumentu
                </Typography>
            </DialogTitle>

            <DialogContent
                dividers
                sx={{ padding: 0 }}
            >
                <Placeholder
                    icon={dragging ? mdiFilePlusOutline : mdiUploadOutline}
                    iconSize={4}
                    text={dragging ? 'Upuść pliki tutaj' : 'Przeciągnij i upuść pliki tutaj, lub'}
                    textVariant="subtitle1"
                    onDrop={onDrop}
                    onDragOver={onDragOver}
                    onDragEnter={onDragEnter}
                    onDragLeave={onDragExit}
                    square
                    sx={{
                        minHeight: '300px',
                        backgroundColor: dragging ? green[50] : grey[200],
                    }}
                >
                    <PrimaryButton
                        sx={{ marginTop: 1 }}
                        disabled={dragging}
                        onClick={() => fileInputRef.current?.click()}
                    >
                        Kliknij tutaj aby wybrać
                    </PrimaryButton>
                </Placeholder>

                <FileInput
                    ref={fileInputRef}
                    type="file"
                    multiple
                    onChange={handleInputChangeEvent}
                />
            </DialogContent>

            <DialogActions>
                <RouterLink to={{ name: RouteNames.DOCUMENTS_HOME }} style={{ width: '100%' }}>
                    <Button fullWidth>
                        Anuluj
                    </Button>
                </RouterLink>
            </DialogActions>
        </Dialog>
    );
}
