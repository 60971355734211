import type { DocumentFileDTO } from '@hnx-archivr/documents';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { FileDownloadCard } from './FileDownloadCard';

export interface FilesListProps {
    files: DocumentFileDTO[];
}

export function FilesList({
    files,
}: FilesListProps) {
    return (
        <Box>
            <Typography variant="h6" fontWeight="regular" marginBottom={1}>
                Pliki
            </Typography>

            <Grid container spacing={3}>
                {
                    files.map((file) => (
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={4} key={file.id}>
                            <FileDownloadCard file={file} />
                        </Grid>
                    ))
                }
            </Grid>
        </Box>
    );
}
