import type { DocumentSearchResultsItem } from '@hnx-archivr/documents';
import Box from '@mui/material/Box';
import { RouterLink } from '@/router';
import { RouteNames } from '../../routes';
import { ResultListItem } from './ResultListItem';

export interface DocumentSearchResultsListProps {
    items: DocumentSearchResultsItem[];
}

export function DocumentsSearchResultsList({
    items,
}: DocumentSearchResultsListProps) {
    return (
        <Box display="flex" flexDirection="column" gap={1} marginTop={2}>
            {
                items.map((item) => (
                    <RouterLink
                        key={item.id}
                        style={{
                            display: 'block',
                            width: '100%',
                        }}
                        to={{
                            name: RouteNames.DOCUMENT_DETAILS,
                            params: { documentId: item.id },
                        }}
                    >
                        <ResultListItem item={item} />
                    </RouterLink>
                ))
            }
        </Box>
    );
}
