import type { SidebarItem } from '@/layout';
import { mdiFileDocumentMultipleOutline, mdiFileSearchOutline } from '@mdi/js';
import { RouteNames } from './routes';
import { Permissions } from '@hnx-archivr/permissions';

export const sidebar: SidebarItem[] = [
    {
        icon: mdiFileDocumentMultipleOutline,
        label: 'Moje dokumenty',
        route: { name: RouteNames.DOCUMENTS_HOME },
        group: 'Dokumenty',
        permissions: [Permissions.documents.view],
    },
    {
        icon: mdiFileSearchOutline,
        label: 'Wyszukaj',
        route: { name: RouteNames.DOCUMENTS_SEARCH },
        group: 'Dokumenty',
        permissions: [Permissions.documents.view],
    },
];
