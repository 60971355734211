import type { RouteDefinition} from '@/router';
import { RouteMetaProperty } from '@/router';
import { DocumentsListPage } from './pages/DocumentsListPage';
import { CreateDocumentPage } from './pages/CreateDocumentPage';
import { DocumentDetailsPage } from './pages/DocumentDetailsPage';
import { EditDocumentPage } from './pages/EditDocumentPage';
import { DocumentsSearchPage } from './pages/DocumentsSearchPage';
import { withAuthGuard } from '@/modules/auth';
import { Permissions } from '@hnx-archivr/permissions';

export const RouteNames = {
    DOCUMENTS_HOME: 'DOCUMENTS_HOME',
    CREATE_DOCUMENT: 'CREATE_DOCUMENT',
    EDIT_DOCUMENT: 'EDIT_DOCUMENT',
    DOCUMENT_DETAILS: 'DOCUMENT_DETAILS',
    DOCUMENTS_SEARCH: 'DOCUMENTS_SEARCH',
} as const;

export const routes: RouteDefinition[] = [
    {
        name: RouteNames.DOCUMENTS_HOME,
        path: '/documents',
        Component: withAuthGuard(DocumentsListPage, [Permissions.documents.view]),
        meta: {
            [RouteMetaProperty.PAGE_TITLE]: 'Dokumenty',
        },
    },
    {
        name: RouteNames.DOCUMENTS_SEARCH,
        path: '/documents/search',
        Component: withAuthGuard(DocumentsSearchPage, [Permissions.documents.view]),
        meta: {
            [RouteMetaProperty.PAGE_TITLE]: 'Wyszukaj dokument',
            [RouteMetaProperty.SIDEBAR_ROUTE]: RouteNames.DOCUMENTS_SEARCH,
        },
    },
    {
        name: RouteNames.CREATE_DOCUMENT,
        path: '/documents/create',
        Component: withAuthGuard(CreateDocumentPage, [Permissions.documents.edit]),
        meta: {
            [RouteMetaProperty.PAGE_TITLE]: 'Tworzenie dokumentu',
            [RouteMetaProperty.SIDEBAR_ROUTE]: RouteNames.DOCUMENTS_HOME,
        },
    },
    {
        name: RouteNames.DOCUMENT_DETAILS,
        path: '/documents/details/:documentId',
        Component: withAuthGuard(DocumentDetailsPage, [Permissions.documents.view]),
        meta: {
            [RouteMetaProperty.PAGE_TITLE]: 'Dokument',
            [RouteMetaProperty.SIDEBAR_ROUTE]: RouteNames.DOCUMENTS_HOME,
        },
    },
    {
        name: RouteNames.EDIT_DOCUMENT,
        path: '/documents/details/:documentId/edit',
        Component: withAuthGuard(EditDocumentPage, [Permissions.documents.edit]),
        meta: {
            [RouteMetaProperty.PAGE_TITLE]: 'Edycja dokumentu',
            [RouteMetaProperty.SIDEBAR_ROUTE]: RouteNames.DOCUMENTS_HOME,
        },
    },
];
