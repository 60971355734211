import {
    mdiFileDelimited,
    mdiFileDocument,
    mdiFileExcel,
    mdiFileImage,
    mdiFileMusic,
    mdiFilePowerpoint,
    mdiFileWord,
    mdiFolderZip,
    mdiFileCode,
    mdiFileVideo,
    mdiFile,
} from '@mdi/js';

export const MimeIcons = {
    'image/jpeg': mdiFileImage,
    'image/png': mdiFileImage,
    'audio/flac': mdiFileMusic,
    'audio/mpeg': mdiFileMusic,
    'audio/wav': mdiFileMusic,
    'audio/ogg': mdiFileMusic,
    'text/plain': mdiFileDocument,
    'text/csv': mdiFileDelimited,
    'application/msword': mdiFileWord,
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': mdiFileWord,
    'application/vnd.ms-word.document.macroEnabled.12': mdiFileWord,
    'application/vnd.ms-excel': mdiFileExcel,
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': mdiFileExcel,
    'application/vnd.ms-excel.sheet.macroEnabled.12': mdiFileExcel,
    'application/vnd.ms-powerpoint': mdiFilePowerpoint,
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': mdiFilePowerpoint,
    'application/vnd.openxmlformats-officedocument.presentationml.slideshow': mdiFilePowerpoint,
    'application/vnd.ms-powerpoint.presentation.macroEnabled.12': mdiFilePowerpoint,
    'application/vnd.ms-powerpoint.slideshow.macroEnabled.12': mdiFilePowerpoint,
    'application/zip': mdiFolderZip,
    'application/x-zip-compressed': mdiFolderZip,
    'application/x-7z-compressed': mdiFolderZip,
    'application/vnd.rar': mdiFolderZip,
    'application/x-tar': mdiFolderZip,
    'application/gzip': mdiFolderZip,
    'application/xml': mdiFileCode,
    'text/html': mdiFileCode,
    'video/mp4': mdiFileVideo,
    'video/mpeg': mdiFileVideo,
    'video/x-msvideo': mdiFileVideo,
    // --------------------------------
    'fallback': mdiFile,
} as const;
