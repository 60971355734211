import type { NavigateOptions } from 'react-router-dom';
import { useNavigate as useReactRouterNavigate } from 'react-router-dom';
import { resolve } from '../resolve';
import type { Route } from '../types';

export type NavigateFunction = (route: Route | string, options?: NavigateOptions) => void;

export function useRouterNavigation() {
    const navigate = useReactRouterNavigate();

    return {
        push,
        replace,
        navigate,
    };

    function push(route: Route) {
        navigate(
            resolve(route.name, route.params, route.query),
            { state: route.data },
        );
    }

    function replace(route: Route) {
        navigate(
            resolve(route.name, route.params, route.query),
            { replace: true, state: route.data },
        );
    }
}
