import { useCurrentRoute } from '@/router';
import type { DocumentFileDTO } from '@hnx-archivr/documents';
import { useState } from 'react';
import { api } from '../../../api';
import { toMessagesList, useToasts } from '@/modules/toasts';
import { getErrorMessages } from '@/core';

export function useFileDownload(file: DocumentFileDTO) {
    const route = useCurrentRoute();
    const toasts = useToasts();
    const [loading, setLoading] = useState(false);

    async function download() {
        setLoading(true);

        try {
            const data = await api.downloadFile(route.params.documentId, file.id);
            saveBlobToFile(data, file.originalFileName);
        } catch (err) {
            toasts.error(toMessagesList(getErrorMessages(err)));
        } finally {
            setLoading(false);
        }
    }

    return {
        loading,
        download,
    };
}

function saveBlobToFile(data: Blob, fileName: string) {
    const href = URL.createObjectURL(data);
    const anchor = document.createElement('a');

    anchor.setAttribute('href', href);
    anchor.setAttribute('download', fileName);
    anchor.style.display = 'none';

    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
}
