import type { JSX} from 'react';
import { useState } from 'react';
import Paper from '@mui/material/Paper';
import type { InputBaseProps } from '@mui/material/InputBase';
import InputBase from '@mui/material/InputBase';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';

export type SoloInputProps = Omit<InputBaseProps, 'value' | 'onChange'> & {
    value: string;
    onChange: (newValue: string) => void;
    onEnterKey?: (value: string) => void;
    prepend?: JSX.Element | string;
    append?: JSX.Element | string;
    elevation?: number;
    clearOnEsc?: boolean;
    loading?: boolean;
    rootElement?: React.ElementType;
}

export function SoloInput({
    value,
    onChange,
    onEnterKey,
    placeholder = '',
    clearOnEsc = false,
    elevation = 3,
    prepend,
    append,
    rootElement = 'div',
    className,
    loading,
    disabled,
    sx,
}: SoloInputProps) {
    const [isFocused, setIsFocused] = useState(false);

    function handleKeyPress(evt: React.KeyboardEvent) {
        switch (evt.key) {
            case 'Esc':
            case 'Escape':
                clearInputOnEsc();
                break;
            case 'Enter':
            case 'Return':
                onEnterKey?.(value);
                break;
        }
    }

    function clearInputOnEsc() {
        if (clearOnEsc) {
            onChange('');
        }
    }

    return (
        <Paper
            component={rootElement}
            sx={{
                paddingX: 2,
                paddingY: 1,
                borderRadius: 30,
                position: 'relative',
                overflow: 'hidden',
                ...sx,
            }}
            elevation={isFocused ? elevation + 2 : elevation}
            className={className}
        >
            <Grid
                container
                direction="row"
                alignItems="center"
                gap={1}
            >
                {prepend}
                <InputBase
                    value={value}
                    onChange={(evt) => onChange(evt.target.value)}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    placeholder={placeholder}
                    onKeyUp={handleKeyPress}
                    sx={{ flexGrow: 1 }}
                    disabled={disabled || loading}
                />
                {append}
            </Grid>
            {loading
                && <LinearProgress
                    sx={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        width: 1,
                    }}
                />
            }
        </Paper>
    );
}
