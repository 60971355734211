import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './observability/sentry';

import { RouterOutlet } from '@/router';
import { AppThemeProvider } from '@/theme';
import { installDashboardModule } from '@/modules/dashboard';
import { AuthProvider, installAuthModule } from '@/modules/auth';
import { installDocumentsModule } from '@/modules/documents';
import { installOwnershipModule } from '@/modules/ownership';
import { installPlaygroundModule } from '@/modules/_playground';
import { ToastsRenderer } from './modules/toasts';

installAuthModule();
installPlaygroundModule();
installDashboardModule();
installOwnershipModule();
installDocumentsModule();

const root = document.getElementById('root');
if (root) {
    ReactDOM.createRoot(root).render(
        <React.StrictMode>
            <AppThemeProvider>
                <ToastsRenderer />
                <AuthProvider>
                    <RouterOutlet />
                </AuthProvider>
            </AppThemeProvider>
        </React.StrictMode>,
    );
}
