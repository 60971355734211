import { Page } from '@/modules/ui/page';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { markdown as changelog } from './CHANGELOG.md';
import Markdown from 'react-markdown';

export function DashboardPage() {
    return (
        <Page>
            <Card
                variant="outlined"
                sx={{
                    maxWidth: 700,
                    margin: 'auto',
                }}
            >
                <CardContent>
                    <Typography variant="h5">
                        Witaj w systemie Archivr!
                    </Typography>
                    <Typography variant="subtitle1">
                        HNX Archivr to system ułatwiający przechowywanie oraz wyszukiwanie dokumentów.
                    </Typography>

                    <Divider sx={{ marginY: 2 }} />

                    <Typography component="div">
                        <Markdown>
                            {changelog}
                        </Markdown>
                    </Typography>
                </CardContent>
            </Card>
        </Page>
    );
}
