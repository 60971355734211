import Icon from '@mdi/react';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { grey } from '@mui/material/colors';

export interface ItemFeatureProps {
    tooltip: string;
    value: string | number;
    icon: string;
}

export function ItemFeature({
    tooltip,
    value,
    icon,
}: ItemFeatureProps) {
    return (
        <Tooltip title={tooltip}>
            <Box display="flex" flexDirection="row" alignItems="center" gap={0.75}>
                <Icon size={1} path={icon} color={grey[700]} />
                <Typography variant="caption">{value}</Typography>
            </Box>
        </Tooltip>
    );
}
