import type { RouteDefinition } from './types';

export const routes: RouteDefinition[] = [];
const routeByName: Record<string, RouteDefinition> = {};

export function registerRoutes(moduleRoutes: RouteDefinition[]) {
    routes.push(...moduleRoutes);
    updateRouteNameToPathMap(routes);
}

export function getPathPatternFromName(routeName: string): string | undefined {
    return routeByName[routeName]?.path;
}

export function getRegisteredRoutes(): RouteDefinition[] {
    return Object.values(Object.values(routeByName));
}

function updateRouteNameToPathMap(routes: RouteDefinition[], parentPath: string = '') {
    routes.forEach((route) => {
        if (route.name && route.path) {
            routeByName[route.name] = route;
        }
        if (Array.isArray(route.children) && route.children.length) {
            const paths = [parentPath];
            if (route.path) {
                paths.push(route.path);
            }
            updateRouteNameToPathMap(
                route.children,
                determineFullPath(paths),
            );
        }
    });
}

function determineFullPath(paths: string[]) {
    return paths.join('/');
}
