import { useState } from 'react';
import type { FormikErrors} from 'formik';
import { useFormik } from 'formik';
import { AttributesSelector } from '../components/attributes-selector';
import type { CreateDocumentDTO, DocumentAttributeDTO, Keyword } from '@hnx-archivr/documents';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { KeywordsSelector } from '../components/keywords-selector';
import { SecondaryButton, SuccessButton } from '@/modules/ui/button';
import { mdiArrowLeft, mdiFloppy } from '@mdi/js';
import { RouteNames } from '../routes';
import { InitialFileUploadDialog } from '../components/initial-file-upload-dialog';
import { FilesSelector } from '../components/files';
import { getErrorMessages, withJoiSchema } from '@/core';
import { createDocumentValidator } from '../validators';
import { useAuth } from '@/modules/auth';
import { PermissionsPicker } from '../components/permissions-picker';
import type { DocumentPermissionDTO } from '@hnx-archivr/documents/permission';
import { Page } from '@/modules/ui/page';
import { api } from '../api';
import { useRouterNavigation } from '@/router';
import { toMessagesList, useToasts } from '@/modules/toasts';
import { createEmptyDocument } from '../models';

export function CreateDocumentPage() {
    const { currentUser } = useAuth();
    const [loading, setLoading] = useState(false);
    const { replace } = useRouterNavigation();
    const toasts = useToasts();

    const form = useFormik<CreateDocumentDTO>({
        initialValues: createEmptyDocument(currentUser),
        validateOnBlur: true,
        validate: withJoiSchema<CreateDocumentDTO>(createDocumentValidator),
        onSubmit: async (values) => {
            setLoading(true);
            try {
                const { id } = await api.create(values, files);
                if (id) {
                    replace({
                        name: RouteNames.DOCUMENT_DETAILS,
                        params: { documentId: id },
                    });
                    toasts.success('Dokument został utworzony!');
                }
            } catch (err) {
                toasts.error(toMessagesList(getErrorMessages(err)));
            }
            finally {
                setLoading(false);
            }
        },
    });

    const [files, setFiles] = useState<File[]>([]);
    const [initialDialogOpened, setInitialDialogOpened] = useState(true);

    function onAttributesUpdate(attributes: DocumentAttributeDTO[]) {
        form.setValues((currentFormData) => ({
            ...currentFormData,
            attributes,
        }));
    }

    function onKeywordsUpdate(keywords: Keyword[]) {
        form.setValues((currentFormData) => ({
            ...currentFormData,
            keywords,
        }));
    }

    function onInitialFilesSelect(selectedFiles: File[]) {
        if (selectedFiles.length) {
            setFiles(selectedFiles);
            form.setValues((currentFormData) => ({
                ...currentFormData,
                name: getInitialDocumentName(selectedFiles[0]),
            }));
            setInitialDialogOpened(false);
        }
    }

    function getInitialDocumentName(file: File) {
        const nameWithoutExt = file.name.replace(/\.[\da-z]+$/i, '');
        return nameWithoutExt.length ? nameWithoutExt : file.name;
    }

    function onAttributeAdd() {
        form.setValues((currentFormData) => ({
            ...currentFormData,
            attributes: [
                ...currentFormData.attributes,
                { name: '', valueType: 'STRING', value: '' },
            ],
        }), false);
    }

    function onPermissionsUpdate(permissions: DocumentPermissionDTO[]) {
        form.setValues((currentFormData) => ({ ...currentFormData, permissions }));
    }

    async function handleSaveClick() {
        const errors = await form.validateForm();
        if (Object.keys(errors).length) {
            toasts.error('Na formularzu znajdują się błędy. Sprawdź formularz i spróbuj ponownie.');
        } else {
            await form.submitForm();
        }
    }

    return (
        <Page
            primaryActions={
                <SuccessButton
                    text="Zapisz dokument"
                    startIcon={mdiFloppy}
                    onClick={handleSaveClick}
                    loading={loading ? loading : undefined}
                />
            }
            secondaryActions={
                <SecondaryButton
                    text="Anuluj"
                    startIcon={mdiArrowLeft}
                    to={{ name: RouteNames.DOCUMENTS_HOME }}
                    disabled={loading}
                />
            }
        >
            <InitialFileUploadDialog
                onFilesSelected={onInitialFilesSelect}
                opened={initialDialogOpened}
            />

            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <TextField
                        value={form.values.name}
                        label="Tytuł"
                        size="small"
                        fullWidth
                        name="name"
                        onChange={form.handleChange}
                        error={!!form.errors.name}
                        helperText={form.errors.name}
                        disabled={loading}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        value={form.values.description}
                        label="Opis"
                        size="small"
                        name="description"
                        fullWidth
                        multiline
                        minRows={4}
                        maxRows={6}
                        onChange={form.handleChange}
                        error={!!form.errors.description}
                        helperText={form.errors.description}
                        disabled={loading}
                    />
                </Grid>

                <Grid item xs={12}>
                    <KeywordsSelector
                        value={form.values.keywords}
                        onChange={onKeywordsUpdate}
                        label="Słowa kluczowe"
                        errors={form.errors.keywords as string[]}
                        disabled={loading}
                    />
                </Grid>

                <Grid item xs={12}>
                    <AttributesSelector
                        value={form.values.attributes}
                        onChange={onAttributesUpdate}
                        onAddClick={onAttributeAdd}
                        errors={form.errors.attributes as FormikErrors<DocumentAttributeDTO>[]}
                        disabled={loading}
                    />
                </Grid>

                <Grid item xs={12}>
                    <PermissionsPicker
                        value={form.values.permissions}
                        onChange={onPermissionsUpdate}
                        disabled={loading}
                    />
                </Grid>

                <Grid item xs={12}>
                    <FilesSelector
                        files={files}
                        onChange={setFiles}
                        disabled={loading}
                    />
                </Grid>
            </Grid>
        </Page>
    );
}
