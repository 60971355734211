import { useEffect, useState } from 'react';
import type { OwnershipSearchResultItem} from '../api';
import { api } from '../api';

const promises: Record<string, Promise<OwnershipSearchResultItem | null>> = {};
const teams: Record<string, OwnershipSearchResultItem> = {};
const users: Record<string, OwnershipSearchResultItem> = {};

export function useDocumentPermissionDetails(entityId: string) {
    const [loading, setLoading] = useState(false);
    const [entity, setEntity] = useState<OwnershipSearchResultItem | null>(teams[entityId] ?? users[entityId] ?? null);

    useEffect(() => {
        if (users[entityId] || teams[entityId] || entity) {
            return;
        }

        (async () => {
            setLoading(true);
            try {
                if (!promises[entityId]) {
                    promises[entityId] = api.ownership.getDetailsById({ entityId });
                }
                const details = await promises[entityId];
                if (details?.type === 'TEAM') {
                    teams[entityId] = details;
                    setEntity(details);
                } else if (details?.type === 'USER') {
                    users[entityId] = details;
                    setEntity(details);
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [entityId, entity]);

    return { loading, details: entity };
}

export function addUserToCache(user: OwnershipSearchResultItem) {
    users[user.id] = user;
}

export function addTeamToCache(team: OwnershipSearchResultItem) {
    teams[team.id] = team;
}

export function clearUsersCache() {
    Object.keys(users).forEach((key) => delete users[key]);
}

export function clearTeamsCache() {
    Object.keys(teams).forEach((key) => delete teams[key]);
}
