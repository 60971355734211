import type { UpdateTeamDTO } from '@hnx-archivr/ownership';
import Joi from 'joi';

export const updateTeamValidator = Joi.object<UpdateTeamDTO>({
    name: Joi.string()
        .min(1)
        .max(255)
        .messages({
            'string.min': 'Nazwa nie może być pusta',
            'string.empty': 'Nazwa nie może być pusta',
            'string.max': 'Nazwa nie może mieć więcej niż 255 znaków',
        }),
    description: Joi.string()
        .allow('')
        .min(0)
        .max(65535)
        .messages({
            'string.max': 'Opis jest zbyt długi',
        })
        .required(),
    slug: Joi.string()
        .max(255)
        .lowercase()
        .regex(/^[a-z0-9-]+$/)
        .messages({
            'string.min': 'Identyfikator nie może być pusty',
            'string.empty': 'Identyfikator nie może być pusty',
            'string.max': 'Identyfikator jest zbyt długi',
            'string.regex': 'Identyfikator może zawierać tylko małe litery, cyfry i znak -',
            'string.lowercase': 'Identyfikator może zawierać tylko małe litery, cyfry i znak -',
        })
        .required(),
    deleted: Joi.boolean().required(),
    parentId: Joi.string().uuid().allow(null).optional(),
    users: Joi.array().optional(),
});
