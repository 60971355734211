import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Typography from '@mui/material/Typography';
import type { KeyboardEvent} from 'react';
import { useRef, useState } from 'react';
import { useAuth } from '../hooks';
import LoadingButton from '@mui/lab/LoadingButton';
import { useToasts } from '@/modules/toasts';
import { mdiEye, mdiEyeOff } from '@mdi/js';
import { IconButton } from '@/modules/ui/button';

export function LoginPage() {
    const auth = useAuth();
    const toasts = useToasts();
    const passwordFieldRef = useRef<HTMLDivElement>(null);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [passwordFieldType, setPasswordFieldType] = useState<'text' | 'password'>('password');

    const paddingX = 3;

    function handleLoginFieldKeyDown(evt: KeyboardEvent) {
        if (evt.key !== 'Enter' && evt.key !== 'Return') {
            return;
        }

        if (username.length && password.length) {
            executeLoginAction();
        } else if (username.length && !password.length) {
            passwordFieldRef.current?.querySelector('input')?.focus();
        }
    }

    function handlePasswordFieldKeyDown(evt: KeyboardEvent) {
        if (evt.key !== 'Enter' && evt.key !== 'Return') {
            return;
        }
        if (username.length && password.length) {
            executeLoginAction();
        }
    }

    function togglePasswordVisibility() {
        setPasswordFieldType((value) => value === 'password' ? 'text' : 'password');
    }

    async function executeLoginAction() {
        try {
            const result = await auth.login(username, password);
            if (!result) {
                toasts.error('Wystąpił błąd podczas próby logowania. Upewnij się, że wprowadzone hasło jest poprawne i spróbuj ponownie.');
            }
        } catch (err) {
            toasts.error('Wystąpił błąd podczas próby logowania. Upewnij się, że wprowadzone hasło jest poprawne i spróbuj ponownie.');
        }
    }

    return (
        <Paper
            elevation={4}
            sx={{
                maxWidth: 500,
                margin: 'auto',
                marginTop: 4,
            }}
        >
            <Typography
                variant="h5"
                paddingX={paddingX}
                paddingY={3}
            >
                Zaloguj się
            </Typography>

            <Grid
                container
                spacing={2}
                paddingX={paddingX}
                marginBottom={2}
            >
                <Grid item xs={12}>
                    <TextField
                        value={username}
                        label="Login"
                        fullWidth
                        autoFocus
                        disabled={auth.loading}
                        onKeyDown={handleLoginFieldKeyDown}
                        onChange={(evt) => setUsername(evt.target.value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <InputLabel>Hasło</InputLabel>
                        <OutlinedInput
                            value={password}
                            ref={passwordFieldRef}
                            label="Hasło"
                            type={passwordFieldType}
                            disabled={auth.loading}
                            onKeyDown={handlePasswordFieldKeyDown}
                            onChange={(evt) => setPassword(evt.target.value)}
                            endAdornment={
                                <IconButton
                                    icon={passwordFieldType === 'password' ? mdiEyeOff : mdiEye}
                                    edge="end"
                                    onClick={togglePasswordVisibility}
                                />
                            }
                        />
                    </FormControl>
                </Grid>
            </Grid>

            <Divider />

            <Box
                paddingX={paddingX}
                paddingY={2}
            >
                <LoadingButton
                    loading={auth.loading}
                    variant="contained"
                    fullWidth
                    disabled={!username || !password}
                    onClick={executeLoginAction}
                >
                    Zaloguj
                </LoadingButton>
            </Box>
        </Paper>
    );
}
