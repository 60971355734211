import type { NonIndexRouteObject } from 'react-router-dom';

export type ParamsObject = Record<string, string>;

export interface Route {
    name: string;
    params?: ParamsObject;
    query?: ParamsObject;
    hash?: string;
    data?: Record<string, any>;
}

export interface CurrentRoute extends Route {
    name: string;
    params: ParamsObject;
    query: ParamsObject;
    hash: string;
    meta: RouteDefinition['meta'] | Record<string, never>,
}

export enum RouteMetaProperty {
    PAGE_TITLE = 'PAGE_TITLE',
    SIDEBAR_ROUTE = 'SIDEBAR_ROUTE',
}

export interface RouteDefinition extends NonIndexRouteObject {
    name: string;
    children?: RouteDefinition[];
    meta?: {
        [RouteMetaProperty.PAGE_TITLE]?: string;
        [RouteMetaProperty.SIDEBAR_ROUTE]?: string;
    };
}
