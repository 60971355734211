export const MimeDisplayNames: Record<string, string> = {
    'application/pdf': 'Dokument PDF',
    'image/jpeg': 'Obraz',
    'image/png': 'Obraz',
    'audio/flac': 'Plik dźwiękowy',
    'audio/mpeg': 'Plik dźwiękowy',
    'audio/wav': 'Plik dźwiękowy',
    'audio/ogg': 'Plik dźwiękowy',
    'text/plain': 'Plik tekstowy',
    'text/csv': 'Plik CSV',
    'application/msword': 'Dokument tekstowy',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'Dokument tekstowy',
    'application/vnd.ms-word.document.macroEnabled.12': 'Dokument tekstowy',
    'application/vnd.ms-excel': 'Arkusz kalkulacyjny',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'Arkusz kalkulacyjny',
    'application/vnd.ms-excel.sheet.macroEnabled.12': 'Arkusz kalkulacyjny',
    'application/vnd.ms-powerpoint': 'Prezentacja',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'Prezentacja',
    'application/vnd.openxmlformats-officedocument.presentationml.slideshow': 'Prezentacja',
    'application/vnd.ms-powerpoint.presentation.macroEnabled.12': 'Prezentacja',
    'application/vnd.ms-powerpoint.slideshow.macroEnabled.12': 'Prezentacja',
    'application/zip': 'Archiwum',
    'application/x-zip-compressed': 'Archiwum',
    'application/x-7z-compressed': 'Archiwum',
    'application/vnd.rar': 'Archiwum',
    'application/x-tar': 'Archiwum',
    'application/gzip': 'Archiwum',
    'application/xml': 'Plik XML',
    'text/html': 'Plik HTML',
    'video/mp4': 'Wideo',
    'video/mpeg': 'Wideo',
    'video/x-msvideo': 'Wideo',
    // --------------------------------
    'fallback': 'Plik',
} as const;
