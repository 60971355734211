import React from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import type { SxProps } from '@mui/material';
import { common } from '@mui/material/colors';

export interface PageActionsProps {

    primary?: React.JSX.Element | React.JSX.Element[] | string;
    secondary?: React.JSX.Element | React.JSX.Element[] | string;
    sx?: SxProps;
}

export function PageActions({
    primary,
    secondary,
    sx = {},
}: PageActionsProps) {
    return (
        <Box
            sx={{
                backgroundColor: common.white,
                zIndex: 500,
                ...sx,
            }}
        >
            <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="flex-start"
            >
                <Box
                    display="flex"
                    flexDirection="row"
                    gap={1}
                >
                    {secondary}
                </Box>
                <Box
                    display="flex"
                    flexDirection="row"
                    gap={1}
                >
                    {primary}
                </Box>
            </Box>
            <Divider
                sx={{
                    marginTop: 2,
                    marginBottom: 2,
                }}
            />
        </Box>
    );
}
