import { Page } from '@/modules/ui/page';
import Card from '@mui/material/Card';
import List from '@mui/material/List';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { useAuth } from '../hooks';
import Icon from '@mdi/react';
import { mdiAccountMultiple, mdiArrowLeft, mdiKeyChainVariant } from '@mdi/js';
import { common } from '@mui/material/colors';
import { PrimaryButton, SecondaryButton } from '@/modules/ui/button';
import { PasswordChangeDialog } from '../components/PasswordChangeDialog';

export function ProfilePage() {
    return (
        <Page
            primaryActions={
                <PasswordChangeDialog
                    DialogTrigger={(open) => (
                        <PrimaryButton
                            startIcon={mdiKeyChainVariant}
                            text="Zmień hasło"
                            onClick={open}
                        />
                    )}
                />

            }
            secondaryActions={
                <SecondaryButton
                    text="Wróć"
                    startIcon={mdiArrowLeft}
                    onClick={() => history.back()}
                />
            }
        >
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <BasicDataCard />
                </Grid>
                <Grid item xs={12} md={6}>
                    <UserTeamsCard />
                </Grid>
            </Grid>
        </Page>
    );
}

function BasicDataCard() {
    const { currentUser } = useAuth();

    return (
        <Card variant="outlined">
            <Typography
                variant="h6"
                fontWeight="regular"
                paddingX={2}
                paddingY={1}
            >
                O mnie
            </Typography>

            <List
                dense
                sx={{
                    bgcolor: common.white,
                    paddingY: 0,
                }}
            >
                <ListItem>
                    <ListItemText
                        primary={<Typography variant="body2" color="text.secondary">Imię i nazwisko</Typography>}
                        secondary={<Typography variant="body1">{currentUser?.fullName}</Typography>}
                    />
                </ListItem>
                <ListItem>
                    <ListItemText
                        primary={<Typography variant="body2" color="text.secondary">Stanowisko</Typography>}
                        secondary={<Typography variant="body1">{currentUser?.position}</Typography>}
                    />
                </ListItem>
                <ListItem>
                    <ListItemText
                        primary={<Typography variant="body2" color="text.secondary">Adres e-mail</Typography>}
                        secondary={<Typography variant="body1">{currentUser?.email}</Typography>}
                    />
                </ListItem>
            </List>
        </Card>
    );
}

function UserTeamsCard() {
    const { currentUser } = useAuth();

    return (
        <Card variant="outlined">
            <Typography
                variant="h6"
                fontWeight="regular"
                paddingX={2}
                paddingY={1}
            >
                Moje zespoły
            </Typography>

            <List
                sx={{
                    bgcolor: common.white,
                    paddingY: 0,
                }}
            >
                {
                    currentUser?.teams.map((team) => (
                        <ListItem key={team.id}>
                            <ListItemAvatar>
                                <Avatar>
                                    <Icon size={1} path={mdiAccountMultiple} />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={team.name} secondary={team.description} />
                        </ListItem>
                    ))
                }
            </List>
        </Card>
    );
}
