import type React from 'react';
import { addToast, dismiss, dismissAll } from '../store';
import type { Toast, ToastType } from '../types';

type UseToasts = Record<ToastType, (message: string | React.JSX.Element) => Toast> & {
    dismiss: typeof dismiss,
    dismissAll: typeof dismissAll,
};

export function useToasts(): UseToasts {
    return {
        success: (message) => addToast('success', message),
        info: (message) => addToast('info', message),
        error: (message) => addToast('error', message),
        warning: (message) => addToast('warning', message),
        dismiss,
        dismissAll,
    };
}
