import { signal } from '@preact/signals-react';

export const dialogs = signal<Record<string, boolean>>({});

export function useConfirmation(id: string) {
    function show() {
        dialogs.value = {
            ...dialogs.value,
            [id]: true,
        };
    }

    function hide() {
        dialogs.value = {
            ...dialogs.value,
            [id]: false,
        };
    }

    return { show, hide };
}
