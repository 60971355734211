import { LoginPage } from './pages/LoginPage';
import type { RouteDefinition } from '@/router';
import { ProfilePage } from './pages/ProfilePage';

export const RouteNames = {
    LOGIN: 'LOGIN',
    PROFILE: 'PROFILE',
} as const;

export const routes: RouteDefinition[] = [
    {
        name: RouteNames.LOGIN,
        path: '/login',
        Component: LoginPage,
    },
    {
        name: RouteNames.PROFILE,
        path: '/profile',
        Component: ProfilePage,
        meta: {
            PAGE_TITLE: 'Profil użytkownika',
        },
    },
];
