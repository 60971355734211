import type { JSX, PropsWithChildren } from 'react';
import PredefinedSkeletons, { type SkeletonVariant } from './skeletons';

export type SkeletonLoaderProps = PropsWithChildren<{
    loading: boolean;
    variant?: SkeletonVariant | JSX.Element;
}>;

export function SkeletonLoader({ loading, children, variant }: SkeletonLoaderProps) {
    const Skeleton = typeof variant === 'string'
        ? PredefinedSkeletons[variant]
        : () => variant;

    return (
        <>{loading ? <Skeleton /> : children}</>
    );
}
