import Avatar from '@mui/material/Avatar';
import { createAvatar } from '@dicebear/core';
import { thumbs } from '@dicebear/collection';
import { memo, useMemo } from 'react';

export interface UserAvatarProps {
    seed?: string;
    size?: number;
}

export const UserAvatar = memo(({
    seed,
    size = 42,
}: UserAvatarProps) => {
    const avatar = createAvatar(thumbs, { seed });
    const dataUri = useMemo(() => avatar.toDataUriSync(), [avatar]);

    return (
        <Avatar
            src={dataUri}
            sx={{
                width: size,
                height: size,
            }}
        />
    );
});
