import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { AttributeRow } from './AttributeRow';
import { PrimaryButton } from '@/modules/ui/button';
import { mdiCardBulletedOffOutline, mdiPlus } from '@mdi/js';
import type { DocumentAttributeDTO } from '@hnx-archivr/documents';
import { Placeholder } from '@/modules/ui/placeholder';
import type { FormikErrors } from 'formik';

export interface AttributesSelectorProps {
    value: DocumentAttributeDTO[];
    onChange: (newAttributes: DocumentAttributeDTO[]) => void;
    onAddClick: () => void;
    errors?: FormikErrors<DocumentAttributeDTO>[];
    disabled?: boolean;
}

export function AttributesSelector({
    value,
    onChange,
    onAddClick,
    errors = [],
    disabled = false,
}: AttributesSelectorProps) {
    function updateAttribute(newAttribute: DocumentAttributeDTO, index: number) {
        Object.assign(value[index], newAttribute);
        onChange(value);
    }

    function removeAttribute(indexToRemove: number) {
        const result = value.filter((_, index) => index !== indexToRemove);
        onChange(result);
    }

    return (
        <Card elevation={0}>
            <CardContent>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    marginBottom={2}
                >
                    <Typography gutterBottom variant="h5" component="div">
                        Atrybuty
                    </Typography>

                    <PrimaryButton
                        text="Dodaj atrybut"
                        startIcon={mdiPlus}
                        size="small"
                        onClick={onAddClick}
                        disabled={disabled}
                    />
                </Box>

                {value.map((attribute, index) => (
                    <AttributeRow
                        key={index}
                        attribute={attribute}
                        onChange={(attribute) => updateAttribute(attribute, index)}
                        onDeleteClick={() => removeAttribute(index)}
                        errors={errors[index]}
                        disabled={disabled}
                    />
                ))}

                {!value.length
                    && <Placeholder
                        icon={mdiCardBulletedOffOutline}
                        text="Nie dodano żadnych atrybutów"
                    />
                }
            </CardContent>
        </Card>
    );
}
