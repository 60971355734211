import { useState } from 'react';
import { api } from '../../api';
import { getErrorMessages } from '@/core';
import debounce from 'lodash/debounce';
import type { DocumentAttributeDTO } from '@hnx-archivr/documents';

const cache = new Map<string, DocumentAttributeDTO[]>();

export function clearCache() {
    cache.clear();
}

export interface AttributeNameSuggestionOptions {
    debounceMs?: number;
    initialQuery?: string;
}

export function useAttributeNameSuggestion(options: AttributeNameSuggestionOptions = {}) {
    const { debounceMs, initialQuery } = options;
    const ucInitialQuery = initialQuery?.toUpperCase();
    const [loading, setLoading] = useState(false);
    const [suggestions, setSuggestions] = useState(ucInitialQuery ? cache.get(ucInitialQuery) ?? [] : []);
    const [errors, setErrors] = useState<string[]>([]);

    async function _search(text: string) {
        const ucText = text.toUpperCase();
        if (!ucText.length) {
            return;
        }

        const fromCache = cache.get(ucText);
        if (fromCache) {
            setSuggestions(fromCache);
            return fromCache;
        }

        setLoading(true);
        try {
            const response = await api.attributes.search(ucText);
            const result: DocumentAttributeDTO[] = response
                .sort((a, b) => b.popularity - a.popularity)
                .map((row) => ({
                    name: row.name,
                    valueType: row.valueType,
                    value: '',
                }));

            cache.set(ucText, result);
            setSuggestions(result);
            return result;
        } catch (err) {
            setErrors(getErrorMessages(err));
            return [];
        } finally {
            setLoading(false);
        }
    }

    const search = typeof debounceMs === 'number'
        ? debounce(_search, debounceMs)
        : _search;

    return {
        loading,
        suggestions,
        errors,
        clearCache,
        search,
    };
}
