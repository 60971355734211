import type { DocumentSearchResultsItem } from '@hnx-archivr/documents';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import { mdiCalendarClock, mdiCommentTextOutline, mdiFileDocumentMultiple } from '@mdi/js';
import { grey } from '@mui/material/colors';
import { ItemKeywords } from './ItemKeywords';
import { ItemFeature } from './ItemFeature';
import removeMarkdown from 'remove-markdown';
import { toDateTimeString } from '@/utils/formatters';

export interface ResultListItemProps {
    item: DocumentSearchResultsItem;
}

export function ResultListItem({ item }: ResultListItemProps) {
    return (
        <Card
            variant="outlined"
            sx={{
                paddingX: 2,
                paddingY: 2,
                '&:hover': {
                    backgroundColor: grey[100],
                },
            }}
        >
            <Typography variant="body1" fontWeight="medium">
                {item.name}
            </Typography>

            <Typography variant="body2" noWrap>
                {removeMarkdown(item.description ?? '')}
            </Typography>

            <ItemKeywords keywords={item.keywords ?? []} itemId={item.id} />

            <Box
                display="flex"
                alignItems="center"
                justifyContent="flex-start"
                gap={2}
                marginTop={2}
            >
                {item.files
                    && <ItemFeature
                        icon={mdiFileDocumentMultiple}
                        tooltip="Załączone pliki"
                        value={item.files}
                    />
                }
                {item.createdAt
                    && <ItemFeature
                        icon={mdiCalendarClock}
                        tooltip="Data utworzenia"
                        value={toDateTimeString(item.createdAt)}
                    />
                }

                {item.comments
                    && <ItemFeature
                        icon={mdiCommentTextOutline}
                        tooltip="Komentarze"
                        value={item.comments}
                    />
                }
            </Box>
        </Card>
    );
}
