import type { Route } from '@/router';
import type { Permission } from '@hnx-archivr/permissions';
import { signal } from '@preact/signals-react';

export interface SidebarItem {
    label: string;
    icon: string;
    route: Route;
    key?: string;
    group?: string;
    permissions?: Permission[];
    children?: Omit<SidebarItem, 'children'>[];
}

const sidebarItems = signal<SidebarItem[]>([]);

export function appendSidebarItems(items: SidebarItem[]) {
    sidebarItems.value = [...sidebarItems.value, ...items]
        .map((item, index) => ({
            ...item,
            key: item.key ?? `sidebar-item-${index}`,
        }));
}

export function clearSidebarItems() {
    sidebarItems.value = [];
}

export function useSidebarItems() {
    return {
        sidebarItems,
    };
}
