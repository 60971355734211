import { isApiError, isError } from '../type-guards';

export function getErrorMessages(value: unknown): string[] {
    if (isApiError(value)) {
        if (!value.response) {
            return [value.message];
        }

        const messages = [
            ...(value.response.data.message ?? []),
            ...(value.response.data?.error?.message ? castToArray(value.response.data.error.message) : []),
        ].filter(Boolean);

        return [...new Set(messages)];
    }

    if (isError(value)) {
        return [value.message];
    }

    try {
        return [JSON.stringify(value)];
    } catch {
        return ['Unknown error'];
    }
}

function castToArray<T>(value: T | T[]): T[] {
    if (Array.isArray(value)) {
        return value;
    }
    return [value];
}
