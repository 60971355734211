import type { FunctionComponent } from 'react';
import { useAuth } from '../hooks';
import { AccessDenied } from './AccessDenied';
import type { Permission } from '@hnx-archivr/permissions';

export function withAuthGuard(Component: FunctionComponent, requiredPermissions: Permission[]) {
    return () => {
        const auth = useAuth();

        const shouldShowAccessDeniedMessage = (
            requiredPermissions.length > 0
            && Array.isArray(auth.currentUser?.credentials?.permissions)
            && !requiredPermissions.every((requiredPermission) => auth.currentUser!.credentials.permissions.includes(requiredPermission))
        );

        return shouldShowAccessDeniedMessage ? <AccessDenied /> : <Component />;
    };
}
