import { SecondaryButton, SuccessButton } from '@/modules/ui/button';
import { Page } from '@/modules/ui/page';
import { mdiArrowLeft, mdiFloppy } from '@mdi/js';
import { RouteNames } from '../routes';
import type { ChangeEvent } from 'react';
import { useEffect, useState } from 'react';
import { useCurrentRoute, useRouterNavigation } from '@/router';
import { toMessagesList, useToasts } from '@/modules/toasts';
import { useFormik } from 'formik';
import type { UpdateUserDTO } from '@hnx-archivr/ownership';
import { getErrorMessages, withJoiSchema } from '@/core';
import { updateUserValidator } from '../validators';
import { api } from '../api';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import kebabCase from 'lodash/kebabCase';
import deburr from 'lodash/deburr';
import { UserPermissions } from '../components/user-permissions';
import { UserTeams } from '../components/user-teams';

export function UserDetails() {
    const [loading, setLoading] = useState(false);
    const { replace } = useRouterNavigation();
    const route = useCurrentRoute();
    const { success, error } = useToasts();

    const form = useFormik<UpdateUserDTO>({
        initialValues: {
            fullName: '',
            email: '',
            position: '',
            deleted: false,
            credentials: {
                login: '',
                permissions: [],
                role: null,
            },
            teams: [],
        },
        validateOnBlur: true,
        validate: withJoiSchema<UpdateUserDTO>(updateUserValidator),
        onSubmit: async (values) => {
            setLoading(true);
            try {
                const { id } = await api.users.update(route.params.userId, values);
                if (id) {
                    replace({
                        name: RouteNames.OWNERSHIP_USER_DETAILS,
                        params: { userId: id },
                    });
                }
                success('Zmiany zostały zapisane!');
            } catch (err) {
                error(toMessagesList(getErrorMessages(err)));
            }
            finally {
                setLoading(false);
            }
        },
    });

    function handleFullNameChange(evt: ChangeEvent<HTMLInputElement>) {
        const fullName = evt.target.value;
        form.setValues((current) => ({
            ...current,
            fullName,
            credentials: {
                ...current.credentials,
                login: kebabCase(deburr(fullName)).replace(/-/g, '.'),
            },
        }));
    }

    useEffect(() => {
        if (!route.params.userId) {
            replace({ name: RouteNames.OWNERSHIP_USERS });
            return;
        }

        (async () => {
            setLoading(true);
            try {
                const user = await api.users.details(route.params.userId);
                form.setValues({
                    fullName: user.fullName,
                    email: user.email,
                    position: user.position,
                    deleted: user.deleted,
                    credentials: {
                        login: user.credentials.login ?? '',
                        permissions: user.credentials.permissions,
                        role: user.credentials.role,
                    },
                    teams: user.teams,
                });
            } catch (err) {
                error(toMessagesList(getErrorMessages(err)));
            } finally {
                setLoading(false);
            }
        })();
    }, [route.params.userId]);

    return (
        <Page
            primaryActions={
                <SuccessButton
                    startIcon={mdiFloppy}
                    text="Zapisz zmiany"
                    onClick={form.submitForm}
                />
            }
            secondaryActions={
                <SecondaryButton
                    startIcon={mdiArrowLeft}
                    text="Wróć"
                    to={{ name: RouteNames.OWNERSHIP_USERS }}
                />
            }
        >
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <TextField
                        value={form.values.fullName}
                        label="Imię i nazwisko"
                        size="small"
                        fullWidth
                        name="fullName"
                        onChange={handleFullNameChange}
                        error={!!form.errors.fullName}
                        helperText={form.errors.fullName}
                        disabled={loading}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        value={form.values.position}
                        label="Stanowisko"
                        size="small"
                        fullWidth
                        name="position"
                        onChange={form.handleChange}
                        error={!!form.errors.position}
                        helperText={form.errors.position}
                        disabled={loading}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        value={form.values.email}
                        label="Adres e-mail"
                        size="small"
                        name="email"
                        fullWidth
                        onChange={form.handleChange}
                        error={!!form.errors.email}
                        helperText={form.errors.email}
                        disabled={loading}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        value={form.values.credentials.login}
                        label="Login"
                        size="small"
                        name="credentials.login"
                        fullWidth
                        onChange={form.handleChange}
                        error={!!form.errors.credentials?.login}
                        helperText={form.errors.credentials?.login}
                        disabled={loading}
                    />
                </Grid>
                <Grid item xs={12}>
                    <UserTeams
                        label="Zespoły użytkownika"
                        value={form.values.teams}
                        onChange={(teams) => form.setFieldValue('teams', teams)}
                        disabled={loading}
                    />
                </Grid>
                <Grid item xs={12}>
                    <UserPermissions
                        label="Uprawnienia użytkownika"
                        value={form.values.credentials.permissions}
                        onChange={(permissions) => form.setFieldValue('credentials.permissions', permissions)}
                    />
                </Grid>
            </Grid>
        </Page>
    );
}
