import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Grid';

export function FilesListSkeleton() {
    return (
        <>
            <Box>
                <Skeleton
                    height={32}
                    variant="rounded"
                    sx={{ marginBottom: 1, maxWidth: 200 }}
                />
            </Box>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={4}>
                    <Card variant="outlined">
                        <Box display="flex" flexDirection="row" alignItems="center" paddingY={1}>
                            <Skeleton variant="rounded" width={64} height={64} sx={{ marginX: 2 }} />
                            <Box display="flex" flexDirection="column" flexGrow={1}>
                                <Skeleton variant="text" height={36} />
                                <Skeleton variant="text" />
                                <Skeleton variant="text" />
                            </Box>
                            <Skeleton variant="circular" width={32} height={32} sx={{ marginX: 2 }} />
                        </Box>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={4}>
                    <Card variant="outlined">
                        <Box display="flex" flexDirection="row" alignItems="center" paddingY={1}>
                            <Skeleton variant="rounded" width={64} height={64} sx={{ marginX: 2 }} />
                            <Box display="flex" flexDirection="column" flexGrow={1}>
                                <Skeleton variant="text" height={36} />
                                <Skeleton variant="text" />
                                <Skeleton variant="text" />
                            </Box>
                            <Skeleton variant="circular" width={32} height={32} sx={{ marginX: 2 }} />
                        </Box>
                    </Card>
                </Grid>
            </Grid>
        </>
    );
}
