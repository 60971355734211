import type { UpdateUserDTO } from '@hnx-archivr/ownership';
import Joi from 'joi';

export const updateUserValidator = Joi.object<UpdateUserDTO>({
    fullName: Joi.string()
        .min(1)
        .max(500)
        .messages({
            'string.min': 'Imię i nazwisko nie może być pusta',
            'string.empty': 'Imię i nazwisko nie może być pusta',
            'string.max': 'Imię i nazwisko nie może mieć więcej niż 500 znaków',
        }),
    email: Joi.string()
        .min(1)
        .max(320)
        .email({ tlds: { allow: false } })
        .messages({
            'string.empty': 'Adres e-mail nie może być pusty',
            'string.min': 'Adres e-mail nie może być pusty',
            'string.email': 'Adres e-mail ma niepoprawny format',
            'string.max': 'Adres e-mail jest zbyt długi',
        })
        .required(),
    position: Joi.string()
        .max(1000)
        .min(0)
        .allow('', null)
        .messages({
            'string.min': 'Nazwa stanowiska nie może być pusta',
            'string.empty': 'Nazwa stanowiska nie może być pusta',
            'string.max': 'Nazwa stanowiska jest zbyt długa',
        })
        .required(),
    credentials: Joi.object({
        login: Joi.string()
            .regex(/[a-z.]/)
            .min(1)
            .max(320)
            .messages({
                'string.min': 'Login nie może być pusty',
                'string.empty': 'Login nie może być pusty',
                'string.max': 'Login jest zbyt długi',
                'string.regex': 'Login może zawierać tylko małe litery i kropki',
            })
            .required(),
        permissions: Joi.array()
            .items(Joi.string())
            .required(),
        role: Joi.string()
            .allow(null)
            .required(),
    }).required(),
    teams: Joi.array().items(
        Joi.object({
            id: Joi.string().uuid().required(),
        }).options({ stripUnknown: true }),
    ).optional(),
    deleted: Joi.boolean().required(),
}).options({ stripUnknown: true });
