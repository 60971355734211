import { useAuth } from '@/modules/auth';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { UserAvatar } from '@/modules/ui/user-avatar';
import { useState } from 'react';
import { useRouterNavigation } from '@/router';
import Icon from '@mdi/react';
import { mdiAccountBox, mdiLogoutVariant } from '@mdi/js';
import Divider from '@mui/material/Divider';

export function SidebarProfileMenu() {
    const { currentUser, logout } = useAuth();
    const { push } = useRouterNavigation();

    const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);
    const menuOpened = Boolean(anchorElement);

    function closeMenu() {
        setAnchorElement(null);
    }

    return (
        <>
            <Menu
                anchorEl={anchorElement}
                open={menuOpened}
                onClose={closeMenu}
            >
                <MenuItem
                    onClick={logout}
                >
                    <ListItemIcon>
                        <Icon size={1} path={mdiLogoutVariant} />
                    </ListItemIcon>
                    <ListItemText
                        primary="Wyloguj się"
                    />
                </MenuItem>
                <Divider />
                <MenuItem
                    onClick={() => {
                        closeMenu();
                        push({ name: 'PROFILE' });
                    }}
                >
                    <ListItemIcon>
                        <Icon size={1} path={mdiAccountBox} />
                    </ListItemIcon>
                    <ListItemText
                        primary="Mój profil"
                        secondary="Przeglądaj dane, zmień hasło"
                    />
                </MenuItem>
            </Menu>
            <List
                sx={{
                    overflow: 'hidden',
                }}
            >
                <ListItem disablePadding>
                    <ListItemButton onClick={(evt) => setAnchorElement(evt.currentTarget)}>
                        <ListItemAvatar>
                            <UserAvatar seed={currentUser?.id ?? ''} />
                        </ListItemAvatar>
                        <ListItemText
                            sx={{
                                '& .MuiListItemText-primary': {
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                },
                                '& .MuiListItemText-secondary': {
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                },
                            }}
                            primary={currentUser?.fullName}
                            secondary={currentUser?.email}
                        />
                    </ListItemButton>
                </ListItem>
            </List>
        </>
    );
}
