import type { DocumentAttributeDTO } from '@hnx-archivr/documents/attribute';
import List from '@mui/material/List';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { common } from '@mui/material/colors';
import { Placeholder } from '@/modules/ui/placeholder';
import { mdiCardBulletedOffOutline } from '@mdi/js';
import { Fragment } from 'react';
import { AttributeValuePreview } from './AttributeValuePreview';

export interface AttributesListProps {
    attributes: DocumentAttributeDTO[];
}

export function AttributesList({
    attributes,
}: AttributesListProps) {
    return (
        <Box>
            <Typography variant="h6" fontWeight="regular" marginBottom={1}>
                Dodatkowe informacje
            </Typography>
            {!!attributes.length
                && <Card variant="outlined">
                    <List
                        sx={{
                            bgcolor: common.white,
                            paddingY: 0,
                        }}
                    >
                        {
                            attributes.map((attribute, index) => (
                                <Fragment key={`${attribute.name}-${index}`}>
                                    <ListItem dense>
                                        <ListItemText
                                            primary={attribute.name}
                                            secondary={
                                                <AttributeValuePreview
                                                    value={attribute.value}
                                                    valueType={attribute.valueType}
                                                />
                                            }
                                        />
                                    </ListItem>
                                    {index < attributes.length - 1 && <Divider />}
                                </Fragment>
                            ))
                        }
                    </List>
                </Card>
            }
            {!attributes.length
                && <Placeholder
                    icon={mdiCardBulletedOffOutline}
                    text="Nie dodano żadnych atrybutów"
                />
            }
        </Box>
    );
}
