import { SecondaryButton, SuccessButton } from '@/modules/ui/button';
import { Page } from '@/modules/ui/page';
import { mdiArrowLeft, mdiFloppy } from '@mdi/js';
import { RouteNames } from '../routes';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import React, { useEffect, useRef, useState } from 'react';
import { useCurrentRoute, useRouterNavigation } from '@/router';
import { toMessagesList, useToasts } from '@/modules/toasts';
import { useFormik } from 'formik';
import type { UpdateTeamDTO } from '@hnx-archivr/ownership';
import { getErrorMessages, withJoiSchema } from '@/core';
import { api } from '../api';
import { updateTeamValidator } from '../validators';
import deburr from 'lodash/deburr';
import kebabCase from 'lodash/kebabCase';

export function TeamDetails() {
    const [loading, setLoading] = useState(false);
    const slugTouched = useRef(false);
    const { replace } = useRouterNavigation();
    const { success, error } = useToasts();
    const route = useCurrentRoute();

    const form = useFormik<UpdateTeamDTO>({
        initialValues: {
            name: route.data?.name ?? '',
            description: route.data?.description ?? '',
            slug: route.data?.slug ?? '',
            parentId: route.data?.parentId ?? null,
            deleted: route.data?.deleted ?? false,

        },
        validateOnBlur: true,
        validate: withJoiSchema<UpdateTeamDTO>(updateTeamValidator, { stripUnknown: true }),
        onSubmit: async (values) => {
            setLoading(true);
            try {
                const { id } = await api.teams.update(route.params.teamId, values);
                if (id) {
                    replace({
                        name: RouteNames.OWNERSHIP_TEAM_DETAILS,
                        params: { teamId: id },
                    });
                }
                success('Zmiany zostały zapisane!');
            } catch (err) {
                error(toMessagesList(getErrorMessages(err)));
            }
            finally {
                setLoading(false);
            }
        },
    });

    function handleNameChange(evt: React.ChangeEvent<HTMLInputElement>) {
        form.handleChange(evt);

        if (!slugTouched.current) {
            form.setFieldValue('slug', kebabCase(deburr(evt.target.value)), false);
        }
    }

    function handleSlugChange(evt: React.ChangeEvent<HTMLInputElement>) {
        form.handleChange(evt);
        slugTouched.current = true;
    }

    useEffect(() => {
        if (!route.params.teamId) {
            replace({ name: RouteNames.OWNERSHIP_TEAMS });
            return;
        }

        (async () => {
            setLoading(true);
            try {
                const team = await api.teams.details(route.params.teamId);
                form.setValues({
                    name: team.name ?? '',
                    slug: team.slug ?? '',
                    description: team.description,
                    deleted: team.deleted,
                    parentId: team.parent?.id ?? null,
                });
            } catch (err) {
                error(toMessagesList(getErrorMessages(err)));
            } finally {
                setLoading(false);
            }
        })();
    }, [route.params.teamId]);

    return (
        <Page
            primaryActions={
                <SuccessButton
                    startIcon={mdiFloppy}
                    text="Zapisz zmiany"
                    onClick={form.submitForm}
                />
            }
            secondaryActions={
                <SecondaryButton
                    startIcon={mdiArrowLeft}
                    text="Wróć"
                    to={{ name: RouteNames.OWNERSHIP_TEAMS }}
                />
            }
        >
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <TextField
                        value={form.values.name}
                        label="Nazwa zespołu"
                        size="small"
                        fullWidth
                        name="name"
                        onChange={handleNameChange}
                        error={!!form.errors.name}
                        helperText={form.errors.name}
                        disabled={loading}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        value={form.values.slug}
                        label="Prosty identyfikator"
                        size="small"
                        fullWidth
                        name="slug"
                        onChange={handleSlugChange}
                        error={!!form.errors.slug}
                        helperText={form.errors.slug}
                        disabled={loading}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        value={form.values.description}
                        label="Opis"
                        size="small"
                        name="description"
                        fullWidth
                        multiline
                        minRows={4}
                        maxRows={6}
                        onChange={form.handleChange}
                        error={!!form.errors.description}
                        helperText={form.errors.description}
                        disabled={loading}
                    />
                </Grid>
            </Grid>
        </Page>
    );
}
