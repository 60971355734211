import type { Params, Location } from 'react-router-dom';
import { useLocation, useParams, matchRoutes } from 'react-router-dom';
import type { CurrentRoute, ParamsObject } from '../types';
import { getRegisteredRoutes } from '../routes';
import { useEffect, useState } from 'react';

export function useCurrentRoute(): CurrentRoute {
    const location = useLocation();
    const params = useParams();
    const hash = getHashValue(location.hash);
    const definition = getRouteDefinition(location);

    const [currentRoute, setCurrentRoute] = useState<CurrentRoute>({
        name: definition?.name ?? null,
        params: getPathParams(params),
        query: getQueryObject(new URLSearchParams(location.search)),
        hash,
        data: location.state,
        meta: definition?.meta ?? {},
    });

    useEffect(() => {
        setCurrentRoute({
            name: definition?.name ?? null,
            params: getPathParams(params),
            query: getQueryObject(new URLSearchParams(location.search)),
            hash,
            data: location.state,
            meta: definition?.meta ?? {},
        });
    }, [location, hash, params, definition]);

    return currentRoute;
}

export function toRoute(href: string): CurrentRoute {
    const definition = getRouteDefinition(href);
    return {
        name: definition?.name ?? null,
        params: {},
        query: getQueryObject(new URLSearchParams(href.split('?')[1] ?? '')),
        hash: href.split('#')[1] ?? '',
        data: undefined,
        meta: definition?.meta ?? {},
    };

}

function getRouteDefinition(location: Location | string) {
    const matchedRoutes = matchRoutes(getRegisteredRoutes(), location);
    const [firstMatch] = matchedRoutes ?? [];
    return firstMatch?.route;
}

function getPathParams(pathParams: Params): ParamsObject {
    return Object.entries(pathParams)
        .reduce((acc, [key, value]) => {
            if (typeof value === 'string') {
                acc[key] = value;
            }
            return acc;
        }, {} as ParamsObject);
}

function getQueryObject(queryParams: URLSearchParams): ParamsObject {
    return Array.from(queryParams.entries()).reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
    }, {} as ParamsObject);
}

function getHashValue(hash: string) {
    return hash.startsWith('#') ? hash.slice(1) : hash;
}
