import type { DocumentPermissionDTO, DocumentPermissionLevel } from '@hnx-archivr/documents/permission';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Icon from '@mdi/react';
import { mdiTrashCanOutline } from '@mdi/js';
import { useDocumentPermissionDetails } from '../../hooks/use-document-permission-details';
import { LABELS, SelectablePermissionLevel } from './types';

export interface PermissionRowProps {
    permission: DocumentPermissionDTO;
    onDeleteClick?: (permission: DocumentPermissionDTO) => void;
    onLevelChange?: (newLevel: DocumentPermissionLevel) => void;
    disabled?: boolean;
}

export function AddedPermissionRow({
    permission,
    onLevelChange,
    onDeleteClick,
    disabled = false,
}: PermissionRowProps) {
    const { details, loading } = useDocumentPermissionDetails(permission.teamId ?? permission.userId ?? '');

    return (
        <Grid container spacing={1} marginBottom={1.5}>
            <Grid item xs={12} sm={6} lg={8} container display="flex" alignItems="center">
                {loading
                    && <div>
                        <CircularProgress size={16} />
                    </div>
                }
                {!loading && details
                    && <Typography variant="body1" component="div" fontWeight="medium">
                        {details.name} ({details.email})
                    </Typography>
                }
                {!loading && !details
                    && <Typography variant="body1" component="div" fontWeight="medium">
                        {permission.userId || permission.teamId || '(brak danych)'}
                    </Typography>
                }
            </Grid>
            <Grid item xs={12} sm={4} lg={3}>
                {permission.level === 'OWNER'
                    && <TextField
                        value={LABELS[permission.level]}
                        size="small"
                        disabled
                        fullWidth
                    />
                }
                {permission.level !== 'OWNER'
                    && <Autocomplete
                        value={permission.level}
                        options={SelectablePermissionLevel}
                        renderInput={(params) => <TextField {...params} disabled={disabled} label="Poziom uprawnień" />}
                        getOptionLabel={(option: DocumentPermissionLevel) => LABELS[option]}
                        onChange={(_, newValue) => onLevelChange?.(newValue!)}
                        size="small"
                        fullWidth
                        disabled={disabled}
                    />
                }
            </Grid>
            <Grid item xs={12} sm={2} lg={1} display="flex" justifyContent="center" alignItems="center">
                <Button
                    startIcon={<Icon size={1} path={mdiTrashCanOutline} />}
                    variant="text"
                    color="error"
                    onClick={() => onDeleteClick?.(permission)}
                    fullWidth
                    disabled={disabled}
                >
                    Usuń
                </Button>
            </Grid>
        </Grid>
    );
}
