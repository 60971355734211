import type { ApiResponse } from '../types';

export function isApiResponse(value: unknown): value is ApiResponse {
    return isPlainObject(value)
        && hasValidMessage(value)
        && hasValidResult(value)
        && hasValidPayload(value)
        && hasValidError(value);
}

function isPlainObject(value: unknown): value is object {
    return typeof value === 'object' && value !== null;
}

function hasValidMessage(value: object): boolean {
    return 'message' in value && (Array.isArray(value.message) || value.message === null);
}

function hasValidResult(value: object): boolean {
    return 'result' in value
        && typeof value.result === 'object'
        && value.result !== null
        && 'success' in value.result
        && typeof value.result.success === 'boolean'
        && 'error' in value.result
        && typeof value.result.error === 'boolean'
        && (value.result.success && !value.result.error || !value.result.success && value.result.error);
}

function hasValidPayload(value: object): boolean {
    return 'payload' in value
        && typeof value.payload !== 'undefined';
}

function hasValidError(value: object): boolean {
    return 'error' in value && isValidErrorDetailsObject(value.error);
}

function isValidErrorDetailsObject(value: unknown): boolean {
    if (typeof value !== 'object') {
        return false;
    }
    if (value === null) {
        return true;
    }

    return 'message' in value && isStringOrStringArray(value.message);
}

function isStringOrStringArray(value: unknown): value is (string | string[]) {
    return typeof value === 'string'
        || Array.isArray(value) && value.every((item) => typeof item === 'string');
}
