import { $http } from '@/core';
import type { CreateDocumentDTO, DocumentDTO, DocumentListItemDTO, KeywordSearchResult, UpdateDocumentDTO, DocumentSearchResultsItem, AttributeSearchResultDTO } from '@hnx-archivr/documents';
import type { PaginatedResult } from '@hnx-archivr/pagination';
import type { DetailsByIdPayload, FileStorageUsage, OwnershipSearchResultItem } from './dtos';

export default {
    downloadFile: (documentId: string, fileId: string) => $http.get<Blob>(`/documents/${documentId}/files/${fileId}/download`, { responseType: 'blob' }),
    list: () => $http.get<PaginatedResult<DocumentListItemDTO>>('/documents/list'),
    details: (documentId: string) => $http.get<DocumentDTO>(`/documents/${documentId}`),
    remove: (documentId: string) => $http.delete<boolean>(`/documents/management/document/${documentId}`),
    create: (dto: CreateDocumentDTO, files: File[]) => {
        const data = new FormData();

        files.forEach((file) => {
            data.append('files', file);
        });
        data.append('document', JSON.stringify(dto));

        return $http.request<DocumentDTO>({
            url: '/documents/management/document',
            method: 'POST',
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            data,
        });
    },
    spaceUsed: () => $http.get<FileStorageUsage>('/documents/storage/used'),
    update: (documentId: string, dto: UpdateDocumentDTO) => $http.patch<DocumentDTO>(`/documents/management/document/${documentId}`, dto),
    keywords: {
        search: (query: string) => $http.get<KeywordSearchResult[]>('/documents/keywords/search', { params: { query } }),
    },
    attributes: {
        search: (query: string) => $http.get<AttributeSearchResultDTO[]>('/documents/attributes/search', { params: { query } }),
    },
    ownership: {
        getDetailsById: (params: DetailsByIdPayload) => $http.get<OwnershipSearchResultItem | null>('/ownership/query/by-id', { params }),
        search: (query: string, limit: number = 10) => $http.get<OwnershipSearchResultItem[]>('/ownership/query', { params: { query, limit } }),
    },
    documentSearch: {
        simple: (query: string, limit: number = 30) => $http.get<DocumentSearchResultsItem[]>('/documents/search/basic', { params: { query, limit } }),
    },
};
