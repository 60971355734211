import { AttributeValueType } from '@hnx-archivr/documents/attribute';
import { DocumentPermissionLevel } from '@hnx-archivr/documents/permission';
import Joi from 'joi';

const name = Joi.string()
    .min(1)
    .max(255)
    .messages({
        'string.min': 'Nazwa nie może być pusta',
        'string.empty': 'Nazwa nie może być pusta',
        'string.max': 'Nazwa nie może mieć więcej niż 255 znaków',
        'string.required': 'Nazwa nie może być pusta',
    })
    .required();

const description = Joi.string()
    .min(0)
    .max(65535)
    .allow('', null)
    .messages({
        'string.max': 'Opis jest zbyt długi',
        'string.required': 'Opis nie jest określony',
    })
    .required();

const keyword = Joi.string()
    .min(1)
    .max(255)
    .messages({
        'string.min': 'Słowo kluczowe jest zbyt krótkie',
        'string.max': 'Słowo kluczowe jest zbyt długie',
    });

const keywords = Joi.array()
    .items(keyword)
    .min(0)
    .messages({
        'array.items': 'Niektóre słowa kluczowe są nieprawidłowe',
    })
    .required();

const attribute = Joi.object({
    name: Joi.string()
        .min(1)
        .max(255)
        .messages({
            'string.min': 'Nazwa atrybutu nie może być pusta',
            'string.max': 'Nazwa atrybutu jest zbyt długa',
            'string.required': 'Nazwa atrybutu nie może być pusta',
            'string.empty': 'Nazwa atrybutu nie może być pusta',
        })
        .required(),
    valueType: Joi.string()
        .valid(...AttributeValueType)
        .messages({
            'string.valid': 'Wybrano nieprawidłowy typ wartości',
            'string.required': 'Typ wartości nie może być pusty',
        })
        .required(),
    value: Joi.alternatives(
        Joi.string().min(1).messages({
            'string.min': 'Wartość nie może być pusta',
            'string.empty': 'Wartość nie może być pusta',
        }),
        Joi.boolean(),
        Joi.number(),
    ).required(),
});

const attributes = Joi.array()
    .items(attribute)
    .min(0)
    .required();

const permission = Joi.object({
    userId: Joi.string()
        .uuid()
        .allow(null)
        .messages({
            'string.uuid': 'Identyfikator użytkownika ma niepoprawny format',
        })
        .required(),
    teamId: Joi.string()
        .uuid()
        .allow(null)
        .messages({
            'string.uuid': 'Identyfikator użytkownika ma niepoprawny format',
        })
        .required(),
    level: Joi.string()
        .valid(...DocumentPermissionLevel)
        .messages({
            'string.valid': 'Wybrano nieprawidłowy typ wartości',
        })
        .required(),
});

const permissions = Joi.array()
    .items(permission)
    .min(1)
    .messages({
        'array.min': 'Dokument musi należeć do przynajmniej jednej osoby lub zespołu',
    })
    .required();

export const createDocumentValidator = Joi.object({
    name,
    description,
    keywords,
    attributes,
    permissions,
});
