import { PrimaryButton } from '@/modules/ui/button';
import { Page } from '@/modules/ui/page';
import { mdiAccountPlusOutline } from '@mdi/js';
import { RouteNames } from '../routes';
import type { DataTableColumn } from '@/modules/ui/data-table';
import { DataTable } from '@/modules/ui/data-table';
import { useState } from 'react';
import type { UserListItemDTO } from '@hnx-archivr/ownership';
import { useRouterNavigation } from '@/router';
import { api } from '../api';
import { useMountEffect } from '@react-hookz/web';

export function UsersList() {
    const [users, setUsers] = useState<UserListItemDTO[]>([]);
    const [loading, setLoading] = useState(false);
    const { push } = useRouterNavigation();

    const columns: DataTableColumn<UserListItemDTO>[] = [
        {
            property: 'fullName',
            headerLabel: 'Imię i nazwisko',
        },
        {
            property: 'position',
            headerLabel: 'Stanowisko',
        },
        {
            property: 'email',
            headerLabel: 'Adres e-mail',
        },
    ];

    function handleRowClick(row: UserListItemDTO) {
        push({
            name: RouteNames.OWNERSHIP_USER_DETAILS,
            params: { userId: row.id },
        });
    }

    function rowFilterFunction(row: UserListItemDTO, searchText: string) {
        const regex = new RegExp(searchText, 'i');
        return regex.test(row.fullName ?? '')
            || regex.test(row.position ?? '')
            || regex.test(row.email ?? '');
    }

    useMountEffect(async () => {
        setLoading(true);
        try {
            const results = await api.users.list();
            setUsers(results);
        } finally {
            setLoading(false);
        }
    });

    return (
        <Page
            primaryActions={
                <PrimaryButton
                    startIcon={mdiAccountPlusOutline}
                    text="Nowy użytkownik"
                    to={{ name: RouteNames.CREATE_OWNERSHIP_USER }}
                />
            }
        >
            <DataTable
                columns={columns}
                rows={users}
                loading={loading}
                rowHover
                filterable={rowFilterFunction}
                onRowClick={handleRowClick}
            />
        </Page>
    );
}

