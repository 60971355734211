import { $http } from '@/core';
import type { UserDTO } from '@hnx-archivr/ownership';
import type { UserPasswordChangeDTO } from './dtos';

export const usersApi = {
    me: () => $http.get<UserDTO>('/accounts/me'),
    login: (login: string, password: string) => $http.post<UserDTO>('/accounts/sign-in', { login, password }),
    logout: () => $http.post<boolean>('/accounts/logout'),
    passwordChange: (payload: UserPasswordChangeDTO) => $http.post<boolean>('/accounts/password-change', payload),
};
