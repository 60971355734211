import { registerRoutes } from '@/router';
import type { DefineModuleOptions } from './types';
import { appendSidebarItems } from '@/layout';

const INDENT = '   ';

export function defineModule({ name, routes, sidebar, onAfterInit }: DefineModuleOptions) {
    console.debug(`⏳ Registering "${name}" module...`);

    if (Array.isArray(routes) && routes.length) {
        registerRoutes(routes);
        console.debug(`${INDENT}➡️ registered ${routes.length} route(s)`);
    }

    if (Array.isArray(sidebar) && sidebar.length) {
        appendSidebarItems(sidebar);
        console.debug(`${INDENT}➡️ registered ${sidebar.length} sidebar item(s)`);
    }

    return () => {
        onAfterInit?.();
    };
}
