import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import { useKeywordSuggestion } from './use-keyword-suggestion';

export interface KeywordsSelectorProps {
    value: string[];
    onChange: (newKeywords: string[]) => void;
    label?: string;
    placeholder?: string;
    errors?: string[];
    disabled?: boolean;
}

export function KeywordsSelector({
    value,
    onChange,
    label,
    placeholder,
    errors = [],
    disabled = false,
}: KeywordsSelectorProps) {
    const { search, suggestions, loading } = useKeywordSuggestion({
        debounceMs: 500,
    });

    function onOptionSelect(_: React.SyntheticEvent, newValue: string[]) {
        onChange?.(newValue.map((keyword) => keyword.toUpperCase()));
    }

    return (
        <Autocomplete
            value={value}
            onChange={onOptionSelect}
            onInputChange={(_, value) => search(value)}
            options={suggestions}
            size="small"
            multiple
            freeSolo
            loading={loading}
            disabled={disabled}
            renderTags={
                (value: readonly string[], getTagProps) => (
                    value.map((option: string, index: number) => (
                        <Chip
                            variant="filled"
                            label={option}
                            {...getTagProps({ index })}
                        />
                    ))
                )
            }
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="outlined"
                    label={label}
                    placeholder={placeholder}
                    error={!!errors?.length}
                    helperText={errors}
                    disabled={disabled}
                />
            )}
        />
    );
}
