import { useState } from 'react';
import { api } from '../../api';
import { format } from 'bytes';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import { mdiHarddisk } from '@mdi/js';
import Icon from '@mdi/react';
import { blueGrey } from '@mui/material/colors';
import { CircularProgress } from '@mui/material';
import { useMountEffect } from '@react-hookz/web';

export function FileStorageUsage() {
    const [loading, setLoading] = useState(false);
    const [usage, setUsage] = useState('(brak danych)');

    useMountEffect(async () => {
        setLoading(true);
        try {
            const payload = await api.spaceUsed();
            setUsage(format(payload.bytes));
        } catch (err) {
            // TODO: Implement
        } finally {
            setLoading(false);
        }
    });

    return (
        <Tooltip title="Użyta przestrzeń dyskowa">
            <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                gap={0.5}
                marginTop={1}
            >
                <Icon path={mdiHarddisk} size={1} color={blueGrey[700]} />
                <Typography variant="body2" component="span" sx={{ cursor: 'default' }}>
                    {loading
                        ? <CircularProgress size={16} sx={{ marginTop: '3px' }} />
                        : usage
                    }
                </Typography>
            </Box>
        </Tooltip>
    );
}
