import { SoloInput } from '@/modules/ui/solo-input';
import { IconButton, PrimaryButton } from '@/modules/ui/button';
import { grey } from '@mui/material/colors';
import Icon from '@mdi/react';
import { mdiClose, mdiMagnify } from '@mdi/js';
import Grid from '@mui/material/Grid';
import { useEffect, useState } from 'react';

export interface SimpleDocumentSearchProps {
    onSearch: (value: string) => void;
    onClear?: () => void;
    loading?: boolean;
}

export function SimpleDocumentSearch({
    onSearch,
    onClear,
    loading = false,
}: SimpleDocumentSearchProps) {
    const [value, setValue] = useState('');

    useEffect(() => {
        if (!value) {
            onClear?.();
        }
    }, [value]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} container justifyContent="center">
                <SoloInput
                    value={value}
                    onChange={setValue}
                    onEnterKey={onSearch}
                    placeholder="Wyszukaj w tytule, atrybutach, treści..."
                    clearOnEsc
                    loading={loading}
                    prepend={
                        <Icon size={1} path={mdiMagnify} color={grey[500]} />
                    }
                    append={value
                        && <IconButton
                            icon={mdiClose}
                            iconSize={0.9}
                            size="small"
                            disabled={loading}
                            onClick={() => setValue('')}
                        />
                    }
                    sx={{
                        width: 1,
                        maxWidth: 500,
                    }}
                />
            </Grid>
            <Grid item xs={12} container gap={1} justifyContent="center">
                <PrimaryButton
                    text="Wyszukaj"
                    disabled={loading}
                    onClick={() => onSearch(value)}
                />
            </Grid>
        </Grid>
    );
}
