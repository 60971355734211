import type { TeamListItemDTO } from '@hnx-archivr/ownership/teams';
import { TEAM_EVERYONE } from '@hnx-archivr/ownership/teams';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useState } from 'react';
import { api } from '../../api';
import { useMountEffect } from '@react-hookz/web';

export interface UserTeamsProps {
    label?: string;
    disabled?: boolean;
    value: TeamListItemDTO[];
    onChange: (teams: TeamListItemDTO[]) => void;
}

export function UserTeams({
    label,
    disabled = false,
    value,
    onChange,
}: UserTeamsProps) {
    const [options, setOptions] = useState<TeamListItemDTO[]>([]);
    const [loading, setLoading] = useState(false);

    function isTeamDisabled(team: TeamListItemDTO): boolean {
        return team.id === TEAM_EVERYONE.id;
    }

    function isOptionEqualToValue(option: TeamListItemDTO, value: TeamListItemDTO): boolean {
        return option.id === value.id;
    }

    useMountEffect(async () => {
        setLoading(true);
        try {
            const payload = await api.ownershipQuery.teamsList();
            setOptions(payload);
        } finally {
            setLoading(false);
        }
    });

    return (
        <Autocomplete
            options={options}
            loading={loading}
            renderInput={(params) => <TextField {...params} label={label} />}
            getOptionDisabled={isTeamDisabled}
            getOptionLabel={(option) => option.name ?? option.slug ?? option.id}
            getOptionKey={(option) => option.id}
            isOptionEqualToValue={isOptionEqualToValue}
            disableCloseOnSelect={true}
            multiple
            fullWidth
            disabled={disabled}
            value={value}
            onChange={(_, newValue) => onChange(newValue)}
        />
    );
}
