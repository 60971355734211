import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Skeleton from '@mui/material/Skeleton';
import Divider from '@mui/material/Divider';

export function PermissionsListSkeleton() {
    return (
        <>
            <Box>
                <Skeleton
                    height={32}
                    variant="rounded"
                    sx={{ marginBottom: 1, maxWidth: 200 }}
                />
            </Box>
            <Card variant="outlined">
                <Box display="flex" flexDirection="row" alignItems="center" paddingY={1}>
                    <Skeleton variant="circular" width={40} height={40} sx={{ marginX: 2 }} />
                    <Box display="flex" flexDirection="column" flexGrow={1} paddingRight={2}>
                        <Skeleton variant="text" />
                        <Skeleton variant="text" />
                    </Box>
                </Box>
                <Divider />
                <Box display="flex" flexDirection="row" alignItems="center" paddingY={1}>
                    <Skeleton variant="circular" width={40} height={40} sx={{ marginX: 2 }} />
                    <Box display="flex" flexDirection="column" flexGrow={1} paddingRight={2}>
                        <Skeleton variant="text" />
                        <Skeleton variant="text" />
                    </Box>
                </Box>
                <Divider />
                <Box display="flex" flexDirection="row" alignItems="center" paddingY={1}>
                    <Skeleton variant="circular" width={40} height={40} sx={{ marginX: 2 }} />
                    <Box display="flex" flexDirection="column" flexGrow={1} paddingRight={2}>
                        <Skeleton variant="text" />
                        <Skeleton variant="text" />
                    </Box>
                </Box>
            </Card>
        </>
    );
}
