import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import { DEFAULT_SIDEBAR_WIDTH } from './constants';
import type { SidebarItem } from '../../hooks';
import { useSidebarItems } from '../../hooks';
import { SidebarListItem } from './SidebarListItem';
import Box from '@mui/material/Box';
import ListSubheader from '@mui/material/ListSubheader';
import { Fragment, useMemo } from 'react';
import { useAuth } from '@/modules/auth';
import type { Permission } from '@hnx-archivr/permissions';
import groupBy from 'lodash/groupBy';
import { SidebarProfileMenu } from '../profile-menu';

export interface SidebarProps {
    width?: string | number;
    opened?: boolean;
    variant?: 'permanent' | 'temporary';
    onBackdropClick?: () => void;
    onSidebarItemClick?: (item: SidebarItem) => void;
}

export interface SidebarGroup {
    groupName: string | null;
    items: SidebarItem[];
}

export function Sidebar({
    width = DEFAULT_SIDEBAR_WIDTH,
    opened = false,
    variant = 'permanent',
    onBackdropClick,
    onSidebarItemClick,
}: SidebarProps) {
    const { sidebarItems } = useSidebarItems();
    const { currentUser } = useAuth();

    const userPermissions: Permission[] = useMemo(() => currentUser?.credentials.permissions ?? [], [currentUser?.credentials.permissions]);

    const groups = useMemo<SidebarGroup[]>(() => {
        const permittedItems = sidebarItems.value
            .filter((item) => {
                if (!item.permissions?.length) {
                    return true;
                }
                return item.permissions.every((permission) => userPermissions.includes(permission));
            })
            .map((item) => ({
                ...item,
                group: item.group ?? '',
            }));

        return Object.entries(groupBy(permittedItems, 'group')).map(([groupName, items]) => ({
            groupName,
            items,
        }));
    }, [userPermissions, sidebarItems.value]);

    return (
        <Drawer
            variant={variant}
            anchor="left"
            open={opened}
            PaperProps={{
                sx: { width },
            }}
            slotProps={{
                backdrop: {
                    onClick: onBackdropClick,
                },
            }}
        >
            <Toolbar disableGutters />

            <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                height={1}
            >
                <List>
                    {
                        groups.map(({ groupName, items }) => (
                            <Fragment key={groupName}>
                                {groupName && <ListSubheader>{groupName}</ListSubheader>}
                                {items.map((item) => (
                                    <SidebarListItem
                                        item={item}
                                        key={item.key}
                                        onClick={() => onSidebarItemClick?.(item)}
                                    />
                                ))}
                            </Fragment>
                        ))
                    }
                </List>

                <Box>
                    <Divider />
                    <SidebarProfileMenu />
                </Box>
            </Box>
        </Drawer>
    );
}
