import { useEffect, useState } from 'react';
import { SimpleDocumentSearch } from '../components/simple-document-search';
import { Page } from '@/modules/ui/page';
import { toMessagesList, useToasts } from '@/modules/toasts';
import { api } from '../api';
import { getErrorMessages } from '@/core';
import { DocumentsSearchResultsList } from '../components/documents-search-results-list';
import type { DocumentSearchResultsItem } from '@hnx-archivr/documents';
import { useSearchParams } from 'react-router-dom';

export function DocumentsSearchPage() {
    const [loading, setLoading] = useState(false);
    const [list, setList] = useState<DocumentSearchResultsItem[]>([]);
    const { error } = useToasts();
    const [searchParams, setSearchParams] = useSearchParams();

    function handleSearch(value: string) {
        setSearchParams(value
            ? new URLSearchParams({ query: value })
            : new URLSearchParams(),
        );
    }

    function handleClear() {
        setSearchParams(new URLSearchParams());
        setList([]);
    }

    useEffect(() => {
        (async () => {
            const value = searchParams.get('query') ?? '';
            if (!value.trim().length) {
                return;
            }

            setLoading(true);
            try {
                const payload = await api.documentSearch.simple(value);
                setList(payload);
            } catch (err) {
                error(toMessagesList(getErrorMessages(err)));
            } finally {
                setLoading(false);
            }
        })();
    }, [searchParams]);

    return (
        <Page>
            <SimpleDocumentSearch
                onSearch={handleSearch}
                onClear={handleClear}
                loading={loading}
            />

            <DocumentsSearchResultsList
                items={list}
            />
        </Page>
    );
}
