import type { ObjectSchema, ValidationOptions } from 'joi';
import set from 'lodash/set';

export function withJoiSchema<Values>(
    schema: ObjectSchema<Values>,
    options: ValidationOptions = {},
): (values: Values) => object {
    return (values) => {
        const validationResult = schema.validate(values, {
            abortEarly: false,
            ...options,
        });
        if (!validationResult.error) {
            return {};
        }

        const results: Record<string, unknown> = {};
        validationResult.error.details.forEach(({ path, message }) => {
            set(results, path, message);
        });

        return results as object;
    };
}
