import { useRouterNavigation } from './use-router-navigation';
import { resolve } from '../resolve';
import type { Route } from '../types';

export function useRouteResolve() {
    const { push } = useRouterNavigation();

    function toHref(route: Route) {
        return resolve(route.name, route.params, route.query, route.hash);
    }

    function toPushFunction(route: Route) {
        return () => push(route);
    }

    return {
        toHref,
        toPushFunction,
    };
}
