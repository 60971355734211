import Switch from '@mui/material/Switch';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

interface TrueFalseSwitchProps {
    value: boolean;
    onChange: (newValue: boolean) => void;
    trueLabel?: string;
    falseLabel?: string;
    disabled?: boolean;
}

export function TrueFalseSwitch({
    value,
    onChange,
    falseLabel = '',
    trueLabel = '',
    disabled = false,
}: TrueFalseSwitchProps) {
    return (
        <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            gap={1}
        >
            <Typography variant="body2" fontWeight={!value ? 'bold' : 'regular'}>
                {falseLabel}
            </Typography>
            <Switch
                color="success"
                value={value}
                onChange={(evt) => onChange(evt.target.checked)}
                disabled={disabled}
            />
            <Typography variant="body2" fontWeight={value ? 'bold' : 'regular'}>
                {trueLabel}
            </Typography>
        </Box>
    );
}
