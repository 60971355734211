import { createTheme, ThemeProvider } from '@mui/material/styles';
import { plPL } from '@mui/material/locale';
import type { PropsWithChildren } from 'react';
import CssBaseline from '@mui/material/CssBaseline';

export const theme = createTheme(undefined, plPL);

type AppThemeProviderProps = PropsWithChildren;

export function AppThemeProvider({ children }: AppThemeProviderProps) {
    return (
        <>
            <CssBaseline />
            <ThemeProvider theme={theme}>
                {children}
            </ThemeProvider>
        </>
    );
}
