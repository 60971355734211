import Button from '@mui/material/Button';
import type { BaseButtonProps } from './types';
import Icon from '@mdi/react';
import { RouterLink } from '@/router';
import CircularProgress from '@mui/material/CircularProgress';
import { common } from '@mui/material/colors';

export function BaseButton(props: BaseButtonProps) {
    let startIcon = typeof props.startIcon === 'string'
        ? <Icon size={props.startIconSize ?? 1} path={props.startIcon} />
        : props.startIcon;

    if (props.loading) {
        startIcon = <CircularProgress size={16} sx={{ color: common.white }} />;
    }

    const endIcon = typeof props.endIcon === 'string'
        ? <Icon size={props.endIconSize ?? 1} path={props.endIcon} />
        : props.endIcon;

    const buttonComponentProps = {
        ...props,
        disabled: props.loading,
        loading: undefined,
        to: undefined,
    };
    const ButtonComponent = (
        <Button {...buttonComponentProps} startIcon={startIcon} endIcon={endIcon}>
            {props.text ?? props.children}
        </Button>
    );

    if (props.to) {
        return (
            <RouterLink to={props.to}>
                {ButtonComponent}
            </RouterLink>
        );
    } else {
        return ButtonComponent;
    }
}
