import { Placeholder } from '@/modules/ui/placeholder';
import { FileSelectorCard } from './FileSelectorCard';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { mdiFileQuestionOutline, mdiFileUpload } from '@mdi/js';
import { red } from '@mui/material/colors';
import { PrimaryButton } from '@/modules/ui/button';
import { styled } from '@mui/material/styles';
import React, { useRef } from 'react';

export interface FilesSelectorProps {
    files: File[];
    onChange: (newFiles: File[]) => void;
    disabled?: boolean;
}

const FileInput = styled('input')({
    width: 1,
    display: 'none',
});

export function FilesSelector({
    files,
    onChange,
    disabled = false,
}: FilesSelectorProps) {
    const fileInputRef = useRef<HTMLInputElement>(null);

    function onDeleteClick(fileToDelete: File) {
        onChange(
            files.filter((file) => file !== fileToDelete),
        );
    }

    function handleInputChangeEvent(evt: React.ChangeEvent<HTMLInputElement>) {
        if (!evt.target.files) {
            return;
        }
        const filesToAdd = [...evt.target.files];
        if (filesToAdd.length) {
            onChange([
                ...files,
                ...filesToAdd,
            ]);
        }
    }

    return (
        <Card elevation={0}>
            <CardContent>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    marginBottom={2}
                >
                    <Typography gutterBottom variant="h5" component="div">
                        Pliki
                    </Typography>

                    <PrimaryButton
                        text="Dodaj plik"
                        startIcon={mdiFileUpload}
                        size="small"
                        onClick={() => fileInputRef.current?.click()}
                        disabled={disabled}
                    />
                </Box>

                <Grid container spacing={1}>
                    {files.map((file, index) => (
                        <Grid xs={12} sm={12} md={12} lg={6} xl={4} item key={`${file.name}-${index}`}>
                            <FileSelectorCard
                                sx={{ height: 1 }}
                                file={file}
                                onDeleteClick={onDeleteClick}
                                disabled={disabled}
                            />
                        </Grid>
                    ))}
                </Grid>

                {!files.length && <Placeholder
                    icon={mdiFileQuestionOutline}
                    text="Dokument musi posiadać przynajmniej jeden plik. Kliknij na przycisk powyżej aby dodać pliki."
                    sx={{
                        backgroundColor: red[50],
                    }}
                />}

                <FileInput
                    ref={fileInputRef}
                    type="file"
                    multiple
                    onChange={handleInputChangeEvent}
                />
            </CardContent>
        </Card>
    );
}
