import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import type { DocumentDTO } from '@hnx-archivr/documents';
import Markdown from 'react-markdown';

export interface BasicDataProps {
    data: Pick<DocumentDTO, 'name' | 'description' | 'keywords'>;
}

export function BasicData({
    data,
}: BasicDataProps) {
    return (
        <Card elevation={0}>
            <CardContent sx={{ padding: 0, paddingBottom: '0 !important' }}>
                <Typography gutterBottom variant="h4" component="div">
                    {data.name}
                </Typography>
                <Typography variant="subtitle1" component="div">
                    <Markdown>
                        {data.description}
                    </Markdown>
                </Typography>
                <Box display="flex" gap={1} marginTop={1}>
                    {data.keywords.map((keyword, index) => (
                        <Chip
                            label={keyword}
                            variant="filled"
                            key={`${keyword}-${index}`}
                        />
                    ))}
                </Box>
            </CardContent>
        </Card>
    );
}
