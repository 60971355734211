import { mdiHandFrontLeft, mdiOctagon } from '@mdi/js';
import { Icon, Stack } from '@mdi/react';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { common, red } from '@mui/material/colors';

export function AccessDenied() {
    return (
        <Card
            elevation={4}
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                paddingY: 2,
                paddingX: 3,
                marginX: 'auto',
                marginY: 5,
                maxWidth: 500,
            }}
        >
            <Stack size={7}>
                <Icon size={7} path={mdiOctagon} color={red[700]} />
                <Icon size={3.5} path={mdiHandFrontLeft} color={common.white} />
            </Stack>
            <Typography variant="body1">Nie masz uprawnień aby zobaczyć wybraną stronę.</Typography>
        </Card>
    );
}

