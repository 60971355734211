import { SecondaryButton, SuccessButton } from '@/modules/ui/button';
import { Page } from '@/modules/ui/page';
import { mdiArrowLeft, mdiFloppy } from '@mdi/js';
import { RouteNames } from '../routes';
import type { ChangeEvent} from 'react';
import { useState } from 'react';
import { useRouterNavigation } from '@/router';
import { toMessagesList, useToasts } from '@/modules/toasts';
import { useFormik } from 'formik';
import type { CreateUserDTO} from '@hnx-archivr/ownership';
import { TEAM_EVERYONE } from '@hnx-archivr/ownership';
import { getErrorMessages, withJoiSchema } from '@/core';
import { createUserValidator } from '../validators';
import { api } from '../api';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import kebabCase from 'lodash/kebabCase';
import deburr from 'lodash/deburr';
import { UserTeams } from '../components/user-teams';
import { UserPermissions } from '../components/user-permissions';

export function CreateUser() {
    const [loading, setLoading] = useState(false);
    const { replace } = useRouterNavigation();
    const { success, error } = useToasts();

    const form = useFormik<CreateUserDTO>({
        initialValues: {
            fullName: '',
            email: '',
            position: '',
            credentials: {
                login: '',
                permissions: [],
                role: null,
            },
            teams: [{
                id: TEAM_EVERYONE.id,
                name: TEAM_EVERYONE.name,
                description: TEAM_EVERYONE.description,
                slug: TEAM_EVERYONE.slug,
            }],
        },
        validateOnBlur: true,
        validate: withJoiSchema<CreateUserDTO>(createUserValidator),
        onSubmit: async (values) => {
            setLoading(true);
            try {
                const { id } = await api.users.create(values);
                if (id) {
                    replace({
                        name: RouteNames.OWNERSHIP_USER_DETAILS,
                        params: { userId: id },
                    });
                }
                success('Użytkownik został utworzony!');
            } catch (err) {
                error(toMessagesList(getErrorMessages(err)));
            }
            finally {
                setLoading(false);
            }
        },
    });

    function handleFullNameChange(evt: ChangeEvent<HTMLInputElement>) {
        const fullName = evt.target.value;
        form.setValues((current) => ({
            ...current,
            fullName,
            credentials: {
                ...current.credentials,
                login: kebabCase(deburr(fullName)).replace(/-/g, '.'),
            },
        }));
    }

    return (
        <Page
            primaryActions={
                <SuccessButton
                    startIcon={mdiFloppy}
                    text="Dodaj użytkownika"
                    onClick={form.submitForm}
                />
            }
            secondaryActions={
                <SecondaryButton
                    startIcon={mdiArrowLeft}
                    text="Anuluj"
                    to={{ name: RouteNames.OWNERSHIP_USERS }}
                />
            }
        >
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <TextField
                        value={form.values.fullName}
                        label="Imię i nazwisko"
                        size="small"
                        fullWidth
                        name="fullName"
                        onChange={handleFullNameChange}
                        error={!!form.errors.fullName}
                        helperText={form.errors.fullName}
                        disabled={loading}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        value={form.values.position}
                        label="Stanowisko"
                        size="small"
                        fullWidth
                        name="position"
                        onChange={form.handleChange}
                        error={!!form.errors.position}
                        helperText={form.errors.position}
                        disabled={loading}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        value={form.values.email}
                        label="Adres e-mail"
                        size="small"
                        name="email"
                        fullWidth
                        onChange={form.handleChange}
                        error={!!form.errors.email}
                        helperText={form.errors.email}
                        disabled={loading}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        value={form.values.credentials.login}
                        label="Login"
                        size="small"
                        name="credentials.login"
                        fullWidth
                        onChange={form.handleChange}
                        error={!!form.errors.credentials?.login}
                        helperText={form.errors.credentials?.login}
                        disabled={loading}
                    />
                </Grid>
                <Grid item xs={12}>
                    <UserTeams
                        label="Zespoły użytkownika"
                        value={form.values.teams}
                        onChange={(teams) => form.setFieldValue('teams', teams)}
                        disabled={loading}
                    />
                </Grid>
                <Grid item xs={12}>
                    <UserPermissions
                        label="Uprawnienia użytkownika"
                        value={form.values.credentials.permissions}
                        onChange={(permissions) => form.setFieldValue('credentials.permissions', permissions)}
                    />
                </Grid>
            </Grid>
        </Page>
    );
}
