import type { PropsWithChildren} from 'react';
import { createContext, useState } from 'react';
import { usersApi } from '../api';
import { getErrorMessages } from '@/core';
import type { UserDTO } from '@hnx-archivr/ownership';

export type AuthProviderProps = PropsWithChildren;

export interface AuthService {
    login(login: string, password: string): Promise<UserDTO | null>;
    logout(): Promise<boolean>;
    fetchCurrentUser(forceFetch?: boolean): Promise<UserDTO | null>;
    currentUser: UserDTO | null;
    loading: boolean;
    isLoggedIn: boolean;
    errors: string[];
}

let currentUserCheckPromise: Promise<UserDTO> | null = null;

export const AuthContext = createContext<AuthService>({
    currentUser: null,
    errors: [],
    isLoggedIn: false,
    loading: false,
    fetchCurrentUser: () => Promise.resolve(null),
    login: () => Promise.resolve(null),
    logout: () => Promise.resolve(false),
});

export function AuthProvider({ children }: AuthProviderProps) {
    const [currentUser, setCurrentUser] = useState<UserDTO | null>(null);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState<string[]>([]);

    async function fetchCurrentUser(forceFetch: boolean = false) {
        if (loading && !currentUserCheckPromise) {
            throw new Error('AuthService cannot fetch current user at this time: logging in or out might be happening at the moment');
        }
        if (!forceFetch && currentUser) {
            return currentUser;
        }

        setLoading(true);
        setErrors([]);

        try {
            currentUserCheckPromise = currentUserCheckPromise ?? usersApi.me();
            const user = await currentUserCheckPromise;
            setCurrentUser(user);
            return user;
        } catch (err) {
            setErrors(getErrorMessages(err));
            setCurrentUser(null);
            return null;
        } finally {
            setLoading(false);
        }
    }

    async function logout() {
        if (loading) {
            throw new Error('AuthService should perform only a single operation at a time');
        }

        setLoading(true);
        setErrors([]);

        try {
            const result = await usersApi.logout();
            if (result) {
                setCurrentUser(null);
                return true;
            }
            return false;
        } catch (err) {
            setErrors(getErrorMessages(err));
            return false;
        } finally {
            setLoading(false);
        }
    }

    async function login(login: string, password: string) {
        if (loading) {
            throw new Error('AuthService should perform only a single operation at a time');
        }

        setLoading(true);
        setErrors([]);

        try {
            const user = await usersApi.login(login, password);
            setCurrentUser(user);
            return user;
        } catch (err: unknown) {
            setErrors(getErrorMessages(err));
            setCurrentUser(null);
            return null;
        } finally {
            setLoading(false);
        }
    }

    const context = {
        fetchCurrentUser,
        login,
        logout,
        loading,
        errors,
        currentUser,
        isLoggedIn: !!currentUser,
    };

    return (
        <AuthContext.Provider value={context}>
            {children}
        </AuthContext.Provider>
    );
}
