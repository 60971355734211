import type { DocumentPermissionDTO, DocumentPermissionLevel } from '@hnx-archivr/documents/permission';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { mdiAccountPlusOutline } from '@mdi/js';
import { addUserToCache, addTeamToCache } from '../../hooks/use-document-permission-details';
import { usePermissionSuggestions } from './use-permission-suggestions';
import { useState } from 'react';
import type { OwnershipSearchResultItem } from '../../api';
import { PrimaryButton } from '@/modules/ui/button';
import { LABELS, SelectablePermissionLevel } from './types';

export interface NewPermissionSectionProps {
    onPermissionAdd: (permission: DocumentPermissionDTO) => void;
    disableIds?: string[];
    disabled?: boolean;
}

export function NewPermissionSection({
    onPermissionAdd,
    disableIds = [],
    disabled = false,
}: NewPermissionSectionProps) {
    const [owner, setOwner] = useState<OwnershipSearchResultItem | null>(null);
    const [level, setLevel] = useState<SelectablePermissionLevel>('READ');
    const { search, suggestions, loading } = usePermissionSuggestions({
        debounceMs: 500,
    });

    function handlePermissionAddClick() {
        const permission = createNewPermission();
        onPermissionAdd(permission);
        setOwner(null);
    }

    function createNewPermission(): DocumentPermissionDTO {
        if (owner?.type === 'USER') {
            addUserToCache(owner);
        }
        if (owner?.type === 'TEAM') {
            addTeamToCache(owner);
        }

        return {
            userId: owner?.type === 'USER' ? owner.id : null,
            teamId: owner?.type === 'TEAM' ? owner.id : null,
            level,
        };
    }

    return (
        <Grid container spacing={1} marginBottom={1.5}>
            <Grid item xs={12} sm={6} container flexDirection="row" alignItems="center">
                <Autocomplete
                    value={owner}
                    onChange={(_, newValue) => setOwner(newValue)}
                    onInputChange={(_, value) => search(value)}
                    getOptionLabel={(option) => `${option.name} (${option.email})`}
                    getOptionDisabled={(option) => disableIds.includes(option.id)}
                    options={suggestions}
                    size="small"
                    loading={loading}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    sx={{
                        flexBasis: 1,
                        flexGrow: 1,
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            disabled={disabled}
                            variant="outlined"
                            label="Użytkownik lub zespół"
                            placeholder="Wyszukaj użytkownika lub zespół"
                        />
                    )}
                    disabled={disabled}
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <Autocomplete
                    value={level}
                    options={SelectablePermissionLevel}
                    renderInput={(params) => <TextField {...params} label="Poziom uprawnień" />}
                    getOptionLabel={(option: DocumentPermissionLevel) => LABELS[option]}
                    onChange={(_, newValue) => setLevel(newValue!)}
                    size="small"
                    fullWidth
                    disabled={disabled}
                />
            </Grid>
            <Grid item xs={12} sm={2}>
                <PrimaryButton
                    text="Dodaj"
                    startIcon={mdiAccountPlusOutline}
                    fullWidth
                    onClick={handlePermissionAddClick}
                    disabled={disabled}
                />
            </Grid>
        </Grid>
    );
}
