import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Skeleton from '@mui/material/Skeleton';
import Divider from '@mui/material/Divider';

export function AttributesListSkeleton() {
    return (
        <>
            <Box>
                <Skeleton
                    height={32}
                    variant="rounded"
                    sx={{ marginBottom: 1, maxWidth: 200 }}
                />
            </Box>
            <Card variant="outlined">
                <Box display="flex" flexDirection="column" flexGrow={1} paddingY={1} paddingX={2}>
                    <Skeleton variant="text" />
                    <Skeleton variant="text" />
                </Box>
                <Divider />
                <Box display="flex" flexDirection="column" flexGrow={1} paddingY={1} paddingX={2}>
                    <Skeleton variant="text" />
                    <Skeleton variant="text" />
                </Box>
                <Divider />
                <Box display="flex" flexDirection="column" flexGrow={1} paddingY={1} paddingX={2}>
                    <Skeleton variant="text" />
                    <Skeleton variant="text" />
                </Box>
            </Card>
        </>
    );
}
