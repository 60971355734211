import { useAuth } from '@/modules/auth';
import { PrimaryButton } from '@/modules/ui/button';
import type { DataTableColumn } from '@/modules/ui/data-table';
import { DataTable } from '@/modules/ui/data-table';
import { Page } from '@/modules/ui/page';
import { useRouterNavigation } from '@/router';
import type { DocumentListItemDTO } from '@hnx-archivr/documents';
import { mdiAccountCircle, mdiAccountMultiple, mdiPlus } from '@mdi/js';
import Icon from '@mdi/react';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { blueGrey } from '@mui/material/colors';
import { useMountEffect } from '@react-hookz/web';
import { useState } from 'react';
import removeMarkdown from 'remove-markdown';
import { api } from '../api';
import { FileStorageUsage } from '../components/file-storage-usage';
import { useDocumentPermissionDetails } from '../hooks/use-document-permission-details';
import { RouteNames } from '../routes';

export function DocumentsListPage() {
    const [documents, setDocuments] = useState<DocumentListItemDTO[]>([]);
    const [loading, setLoading] = useState(false);
    const { currentUser } = useAuth();
    const { push } = useRouterNavigation();

    const columns: DataTableColumn<DocumentListItemDTO>[] = [
        {
            property: 'name',
            headerLabel: 'Tytuł',
        },
        {
            property: 'description',
            headerLabel: 'Opis',
            CellComponent: ({ row }) => (
                removeMarkdown(row.description ?? '')
            ),
        },
        {
            property: 'keywords',
            headerLabel: 'Słowa kluczowe',
            CellComponent: ({ row }) => (
                <Box display="flex" gap={0.5}>
                    {row.keywords.map((keyword) => (
                        <Chip variant="filled" label={keyword} key={`keyword-${row.id}-${keyword}`} size="small" />
                    ))}
                </Box>
            ),
        },
        {
            property: 'owner',
            headerLabel: 'Właściciel',
            CellComponent: ({ row }) => {
                const { details, loading } = useDocumentPermissionDetails(row.owner ?? '');
                const icon = details?.type === 'USER' ? mdiAccountCircle : mdiAccountMultiple;
                const displayName = currentUser?.id === row.owner ? 'Ja' : details?.name;

                return (
                    <Box display="flex" justifyContent="flex-start" alignItems="center">
                        {loading && <CircularProgress size={21} />}
                        {!loading
                            && <>
                                <Icon size={1.25} path={icon} color={blueGrey[700]} />
                                <Typography variant="body2" sx={{ marginLeft: 0.5 }}>
                                    {displayName}
                                </Typography>
                            </>
                        }
                    </Box>
                );
            },
        },
        {
            property: 'createdAt',
            headerLabel: 'Utworzono',
            CellComponent: ({ row }) => {
                return Intl.DateTimeFormat(window.navigator.language, {
                    day: 'numeric',
                    month: 'short',
                    year: 'numeric',
                    hour: 'numeric',
                    minute: '2-digit',
                }).format(new Date(row.createdAt));
            },
        },
    ];

    function handleRowClick(row: DocumentListItemDTO) {
        push({
            name: RouteNames.DOCUMENT_DETAILS,
            params: { documentId: row.id },
        });
    }

    function rowFilterFunction(row: DocumentListItemDTO, searchText: string) {
        const regex = new RegExp(searchText, 'i');
        return regex.test(row.name)
            || regex.test(row.description ?? '')
            || row.keywords.some((keyword) => regex.test(keyword));
    }

    useMountEffect(async () => {
        setLoading(true);
        try {
            const results = await api.list();
            setDocuments(results.items);
        } finally {
            setLoading(false);
        }
    });

    return (
        <Page
            primaryActions={
                <PrimaryButton
                    startIcon={mdiPlus}
                    text="Dodaj"
                    to={{ name: RouteNames.CREATE_DOCUMENT }}
                />
            }
            secondaryActions={
                <FileStorageUsage />
            }
        >
            <DataTable
                columns={columns}
                rows={documents}
                loading={loading}
                rowHover
                filterable={rowFilterFunction}
                onRowClick={handleRowClick}
            />
        </Page>
    );
}
