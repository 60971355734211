import type { RouteDefinition} from '@/router';
import { RouteMetaProperty } from '@/router';
import { TeamsList } from './pages/TeamsList';
import { CreateTeam } from './pages/CreateTeam';
import { TeamDetails } from './pages/TeamDetails';
import { UserDetails } from './pages/UserDetails';
import { CreateUser } from './pages/CreateUser';
import { UsersList } from './pages/UsersList';
import { withAuthGuard } from '@/modules/auth';
import { Permissions } from '@hnx-archivr/permissions';

export const RouteNames = {
    OWNERSHIP_TEAMS: 'OWNERSHIP_TEAMS',
    CREATE_OWNERSHIP_TEAM: 'CREATE_OWNERSHIP_TEAM',
    OWNERSHIP_TEAM_DETAILS: 'OWNERSHIP_TEAM_DETAILS',
    OWNERSHIP_USERS: 'OWNERSHIP_USERS',
    CREATE_OWNERSHIP_USER: 'CREATE_OWNERSHIP_USER',
    OWNERSHIP_USER_DETAILS: 'OWNERSHIP_USER_DETAILS',
} as const;

export const routes: RouteDefinition[] = [
    // Teams
    {
        name: RouteNames.OWNERSHIP_TEAMS,
        path: '/ownership/teams',
        Component: withAuthGuard(TeamsList, [Permissions.ownership.teams.browse]),
        meta: {
            [RouteMetaProperty.PAGE_TITLE]: 'Zespoły',
            [RouteMetaProperty.SIDEBAR_ROUTE]: RouteNames.OWNERSHIP_TEAMS,
        },
    },
    {
        name: RouteNames.CREATE_OWNERSHIP_TEAM,
        path: '/ownership/teams/create',
        Component: withAuthGuard(CreateTeam, [Permissions.ownership.teams.create]),
        meta: {
            [RouteMetaProperty.PAGE_TITLE]: 'Nowy zespół',
            [RouteMetaProperty.SIDEBAR_ROUTE]: RouteNames.OWNERSHIP_TEAMS,
        },
    },
    {
        name: RouteNames.OWNERSHIP_TEAM_DETAILS,
        path: '/ownership/teams/:teamId',
        Component: withAuthGuard(TeamDetails, [Permissions.ownership.teams.browse]),
        meta: {
            [RouteMetaProperty.PAGE_TITLE]: 'Szczegóły zespołu',
            [RouteMetaProperty.SIDEBAR_ROUTE]: RouteNames.OWNERSHIP_TEAMS,
        },
    },
    // Users
    {
        name: RouteNames.OWNERSHIP_USERS,
        path: '/ownership/users',
        Component: withAuthGuard(UsersList, [Permissions.ownership.users.browse]),
        meta: {
            [RouteMetaProperty.PAGE_TITLE]: 'Użytkownicy',
            [RouteMetaProperty.SIDEBAR_ROUTE]: RouteNames.OWNERSHIP_USERS,
        },
    },
    {
        name: RouteNames.CREATE_OWNERSHIP_USER,
        path: '/ownership/users/create',
        Component: withAuthGuard(CreateUser, [Permissions.ownership.users.create]),
        meta: {
            [RouteMetaProperty.PAGE_TITLE]: 'Utwórz użytkownika',
            [RouteMetaProperty.SIDEBAR_ROUTE]: RouteNames.OWNERSHIP_USERS,
        },
    },
    {
        name: RouteNames.OWNERSHIP_USER_DETAILS,
        path: '/ownership/users/:userId',
        Component: withAuthGuard(UserDetails, [Permissions.ownership.users.browse]),
        meta: {
            [RouteMetaProperty.PAGE_TITLE]: 'Szczegóły użytkownika',
            [RouteMetaProperty.SIDEBAR_ROUTE]: RouteNames.OWNERSHIP_USERS,
        },
    },
];
