import type { CreateDocumentDTO } from '@hnx-archivr/documents';
import type { UserDTO } from '@hnx-archivr/ownership';

export function createEmptyDocument(currentUser?: UserDTO | null): CreateDocumentDTO {
    return {
        name: '',
        description: '',
        keywords: [],
        attributes: [],
        permissions: [
            { userId: currentUser?.id ?? null, teamId: null, level: 'OWNER' },
        ],
    };
}
