import { useRouteResolve } from './use-route-resolve';
import { useCurrentRoute } from './use-current-route';
import type { CurrentRoute } from '../types';
import { useEffect } from 'react';

export type RouteChangeWatcher = (currentRoute: CurrentRoute) => void;

export function useWatchRouteChange(handler: RouteChangeWatcher) {
    const route = useCurrentRoute();
    const { toHref } = useRouteResolve();

    const fullPath = route?.name ? toHref(route) : '';

    useEffect(() => {
        handler(route);
    }, [fullPath]);
}
