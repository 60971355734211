import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

export interface LoaderProps {
    loading?: boolean;
}

export function Loader({
    loading = false,
}: LoaderProps) {
    return (
        <>
            {loading
                && <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                    padding={4}
                >
                    <CircularProgress
                        size={64}
                        data-testid="loader"
                    />
                </Box>
            }
        </>
    );
}

