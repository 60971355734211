import Box from '@mui/material/Box';

export interface MessagesListProps {
    messages: string[];
}

export function MessagesList({
    messages,
}: MessagesListProps) {
    return (
        <Box component="div" display="flex" flexDirection="column" gap={1}>
            {messages.map((message) => (
                <Box key={message}>{message}</Box>
            ))}
        </Box>
    );
}
