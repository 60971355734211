import type { DocumentFileDTO } from '@hnx-archivr/documents';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import { blueGrey } from '@mui/material/colors';
import { mdiDownloadCircleOutline } from '@mdi/js';
import Icon from '@mdi/react';
import type { MimeType } from '../formatting';
import { MimeDisplayNames, MimeIcons } from '../formatting';
import { format } from 'bytes';
import LinearProgress from '@mui/material/LinearProgress';
import { useFileDownload } from './use-file-download';

export interface FileDownloadCardProps {
    file: DocumentFileDTO;
}

export function FileDownloadCard({
    file,
}: FileDownloadCardProps) {
    const { download, loading } = useFileDownload(file);

    const iconPath = MimeIcons[file.mime as MimeType] ?? MimeIcons.fallback;
    const humanReadableType = MimeDisplayNames[file.mime as MimeType] ?? MimeDisplayNames.fallback;

    return (
        <Card
            variant="outlined"
            sx={{
                display: 'flex',
                flexDirection: 'row',
                cursor: 'pointer',
                position: 'relative',
                '&:hover': {
                    backgroundColor: blueGrey[50],
                },
                pointerEvents: loading ? 'none' : 'all',
            }}
            onClick={() => download()}
        >
            <Box
                display="flex"
                paddingY={1.5}
                paddingLeft={1.5}
                alignItems="center"
            >
                <Avatar
                    variant="rounded"
                    sx={{
                        width: 64,
                        height: 64,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        bgcolor: blueGrey[200],
                    }}
                >
                    <Icon size={1.75} path={iconPath} />
                </Avatar>
            </Box>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                padding: 1.5,
            }}>
                <Typography component="div" variant="subtitle1" fontWeight="medium">
                    {file.originalFileName}
                </Typography>
                <Typography variant="body2" color="text.secondary" component="div">
                    {humanReadableType}
                </Typography>
                <Typography variant="body2" color="text.secondary" component="div">
                    {format(+file.size)}
                </Typography>
            </Box>
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                padding={1}
                paddingRight={2}
            >
                <Icon
                    size={1.5}
                    path={mdiDownloadCircleOutline}
                    color={blueGrey[700]}
                />
            </Box>

            {loading
                && <Box position="absolute" width={1} bottom={0}>
                    <LinearProgress variant="indeterminate" sx={{ width: '100%' }} />
                </Box>
            }
        </Card>
    );
}
