import Joi from 'joi';
import type { UserPasswordChangeDTO } from '../api';

export const passwordChangeFormValidator = Joi.object<UserPasswordChangeDTO>({
    currentPassword: Joi.string().required().messages({
        'string.empty': 'Hasło nie może być puste',
    }),
    newPassword: Joi.string()
        .min(10)
        .max(72)
        .pattern(/[a-z]/, { name: 'lowercase' })
        .pattern(/[A-Z]/, { name: 'uppercase' })
        .pattern(/[0-9]/, { name: 'digits' })
        .messages({
            'string.empty': 'Hasło musi zawierać przynajmniej 10 znaków',
            'string.min': 'Hasło musi zawierać przynajmniej 10 znaków',
            'string.max': 'Hasło nie może być dłuższe niż 72 znaki',
            'string.pattern.digits': 'Hasło musi zawierać przynajmniej jedną cyfrę',
            'string.pattern.lowercase': 'Hasło musi zawierać przynajmniej jedną małą literę',
            'string.pattern.uppercase': 'Hasło musi zawierać przynajmniej jedną wielką literę',
            'string.pattern.name': 'Hasło musi zawierać przynajmniej: jedną wielką literę, jedną małą literę i jedną cyfrę',
        })
        .required(),
    repeatPassword: Joi
        .ref('newPassword'),
});
