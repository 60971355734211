import type { DocumentPermissionDTO, DocumentPermissionLevel } from '@hnx-archivr/documents/permission';
import { useDocumentPermissionDetails } from '../../hooks/use-document-permission-details';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import { mdiAccountMultiple, mdiAccountOutline, mdiHeadQuestionOutline, mdiShareVariant } from '@mdi/js';
import Icon from '@mdi/react';
import { common } from '@mui/material/colors';
import { Fragment } from 'react';
import { TitleWithAction } from './TitleWithAction';

export interface PermissionsListProps {
    permissions: DocumentPermissionDTO[];
}

export function PermissionsList({
    permissions,
}: PermissionsListProps) {
    return (
        <Box>
            <TitleWithAction
                header="Dostęp do dokumentu"
                actionIcon={mdiShareVariant}
                actionTitle="Udostępnij"
            />
            <Card variant="outlined">
                <List
                    sx={{
                        bgcolor: common.white,
                        paddingY: 0,
                    }}
                >
                    {
                        permissions.map((permission, index) => (
                            <Fragment key={`permission-${permission.level}-${index}`}>
                                <PermissionListItem permission={permission} />
                                {index < permissions.length - 1 && <Divider />}
                            </Fragment>
                        ))
                    }
                </List>
            </Card>
        </Box>
    );
}

const LABELS: Record<DocumentPermissionLevel, string> = {
    OWNER: 'Właściciel',
    READ: 'Przeglądający',
    WRITE: 'Edytujący',
};

function PermissionListItem({ permission }: { permission: DocumentPermissionDTO }) {
    const { details, loading } = useDocumentPermissionDetails(permission.teamId ?? permission.userId ?? '');
    const icon = (() => {
        if (details?.type === 'TEAM') {
            return mdiAccountMultiple;
        }
        if (details?.type === 'USER') {
            return mdiAccountOutline;
        }
        return mdiHeadQuestionOutline;
    })();
    const displayName = loading ? 'Wczytywanie danych...' : `${details?.name} (${details?.email})`;
    const permissionLabel = LABELS[permission.level];

    return (
        <ListItem dense>
            <ListItemAvatar>
                <Avatar>
                    <Icon size={1} path={icon} />
                </Avatar>
            </ListItemAvatar>
            <ListItemText primary={displayName} secondary={permissionLabel} />
        </ListItem>
    );
}
