import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { SuccessButton } from '@/modules/ui/button';
import { mdiCheck } from '@mdi/js';
import { useFormik } from 'formik';
import { getErrorMessages, withJoiSchema } from '@/core';
import type { UserPasswordChangeDTO} from '../api';
import { usersApi } from '../api';
import { passwordChangeFormValidator } from '../validators';
import { toMessagesList, useToasts } from '@/modules/toasts';

export interface PasswordChangeDialogProps {
    DialogTrigger: (open: () => void) => React.JSX.Element;
}

export function PasswordChangeDialog({
    DialogTrigger,
}: PasswordChangeDialogProps) {
    const toasts = useToasts();
    const [dialogOpened, setDialogOpened] = useState(false);
    const [loading, setLoading] = useState(false);

    const form = useFormik<UserPasswordChangeDTO>({
        initialValues: {
            currentPassword: '',
            newPassword: '',
            repeatPassword: '',
        },
        validateOnChange: true,
        isInitialValid: false,
        validate: withJoiSchema<UserPasswordChangeDTO>(passwordChangeFormValidator),
        onSubmit: async (values) => {
            setLoading(true);
            try {
                const result = await usersApi.passwordChange(values);
                if (result) {
                    toasts.success('Twoje hasło zostało pomyślnie zmienione!');
                    setDialogOpened(false);
                }
            } catch (err) {
                toasts.error(toMessagesList(getErrorMessages(err)));
            } finally {
                setLoading(false);
            }
        },
    });

    function openDialog() {
        setDialogOpened(true);
    }

    return (
        <>
            {DialogTrigger(openDialog)}

            <Dialog
                open={dialogOpened}
                fullWidth
                sx={{
                    zIndex: 9000,
                }}
            >
                <DialogTitle>
                    Zmiana hasła
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container gap={1}>
                        <Grid item xs={12}>
                            <TextField
                                label="Aktualne hasło"
                                variant="outlined"
                                fullWidth
                                name="currentPassword"
                                type="password"
                                value={form.values.currentPassword}
                                onChange={form.handleChange}
                                disabled={loading}
                                error={!!form.errors.currentPassword}
                                helperText={form.errors.currentPassword}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Nowe hasło"
                                variant="outlined"
                                fullWidth
                                name="newPassword"
                                type="password"
                                value={form.values.newPassword}
                                onChange={form.handleChange}
                                disabled={loading}
                                error={!!form.errors.newPassword}
                                helperText={form.errors.newPassword}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Powtórz nowe hasło"
                                variant="outlined"
                                fullWidth
                                name="repeatPassword"
                                type="password"
                                value={form.values.repeatPassword}
                                onChange={form.handleChange}
                                disabled={loading}
                                error={!!form.errors.repeatPassword}
                                helperText={form.errors.repeatPassword ? 'Podane hasła nie są takie same' : undefined}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions
                >
                    <SuccessButton
                        text="Zmień hasło"
                        startIcon={mdiCheck}
                        loading={loading || undefined}
                        disabled={!form.isValid}
                        onClick={form.submitForm}
                    />
                    <Button
                        onClick={() => setDialogOpened(false)}
                        disabled={loading}
                    >
                        Anuluj
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
