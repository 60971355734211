import { SecondaryButton, SuccessButton } from '@/modules/ui/button';
import { Page } from '@/modules/ui/page';
import { useCurrentRoute, useRouterNavigation } from '@/router';
import { mdiArrowLeft, mdiFloppy } from '@mdi/js';
import { RouteNames } from '../routes';
import { useState } from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { PermissionsPicker } from '../components/permissions-picker';
import { AttributesSelector } from '../components/attributes-selector';
import { KeywordsSelector } from '../components/keywords-selector';
import type { FormikErrors} from 'formik';
import { useFormik } from 'formik';
import type { DocumentAttributeDTO } from '@hnx-archivr/documents/attribute';
import type { CreateDocumentDTO, UpdateDocumentDTO } from '@hnx-archivr/documents';
import { createDocumentValidator } from '../validators';
import { getErrorMessages, withJoiSchema } from '@/core';
import { toMessagesList, useToasts } from '@/modules/toasts';
import { api } from '../api';
import { useMountEffect } from '@react-hookz/web';

export function EditDocumentPage() {
    const route = useCurrentRoute();
    const toasts = useToasts();
    const { replace } = useRouterNavigation();

    const [loading, setLoading] = useState(false);

    const form = useFormik<CreateDocumentDTO>({
        initialValues: {
            name: route.data?.name ?? '',
            description: route.data?.description ?? '',
            keywords: route.data?.keywords ?? [],
            attributes: route.data?.attributes ?? [],
            permissions: route.data?.permissions ?? [],
        },
        validateOnBlur: true,
        validate: withJoiSchema<CreateDocumentDTO>(createDocumentValidator),
        onSubmit: async (values) => {
            setLoading(true);
            try {
                const { id } = await api.update(route.params.documentId, values);
                if (id) {
                    replace({
                        name: RouteNames.DOCUMENT_DETAILS,
                        params: { documentId: id },
                    });
                    toasts.success('Zmiany zostały zapisane!');
                }
            } catch (err) {
                toasts.error(toMessagesList(getErrorMessages(err)));
            }
            finally {
                setLoading(false);
            }
        },
    });

    function onFormValuesUpdate(values: Partial<UpdateDocumentDTO>) {
        form.setValues((currentFormData) => ({ ...currentFormData, ...values }));
    }

    function onAttributeAdd() {
        form.setValues((currentFormData) => ({
            ...currentFormData,
            attributes: [
                ...currentFormData.attributes,
                { name: '', valueType: 'STRING', value: '' },
            ],
        }), false);
    }

    async function handleSaveClick() {
        const errors = await form.validateForm();
        if (Object.keys(errors).length) {
            toasts.error('Na formularzu znajdują się błędy. Sprawdź formularz i spróbuj ponownie.');
        } else {
            await form.submitForm();
        }
    }

    useMountEffect(() => {
        if (!route.data) {
            replace({
                name: RouteNames.DOCUMENT_DETAILS,
                params: route.params,
            });
        }
    });

    return (
        <Page
            primaryActions={
                <SuccessButton
                    text="Zapisz zmiany"
                    startIcon={mdiFloppy}
                    onClick={handleSaveClick}
                    loading={loading ? loading : undefined}
                />
            }
            secondaryActions={
                <SecondaryButton
                    startIcon={mdiArrowLeft}
                    to={{
                        name: RouteNames.DOCUMENT_DETAILS,
                        params: route.params,
                    }}
                    text="Powrót"
                    disabled={loading}
                />
            }
        >

            <Grid container spacing={3} sx={{ padding: 2 }}>
                <Grid item xs={12}>
                    <TextField
                        value={form.values.name}
                        label="Tytuł"
                        size="small"
                        fullWidth
                        name="name"
                        onChange={form.handleChange}
                        error={!!form.errors.name}
                        helperText={form.errors.name}
                        disabled={loading}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        value={form.values.description}
                        label="Opis"
                        size="small"
                        name="description"
                        fullWidth
                        multiline
                        minRows={4}
                        maxRows={6}
                        onChange={form.handleChange}
                        error={!!form.errors.description}
                        helperText={form.errors.description}
                        disabled={loading}
                    />
                </Grid>

                <Grid item xs={12}>
                    <KeywordsSelector
                        value={form.values.keywords}
                        onChange={(keywords) => onFormValuesUpdate({ keywords })}
                        label="Słowa kluczowe"
                        errors={form.errors.keywords as string[]}
                        disabled={loading}
                    />
                </Grid>

                <Grid item xs={12}>
                    <AttributesSelector
                        value={form.values.attributes}
                        onChange={(attributes) => onFormValuesUpdate({ attributes })}
                        onAddClick={onAttributeAdd}
                        errors={form.errors.attributes as FormikErrors<DocumentAttributeDTO>[]}
                        disabled={loading}
                    />
                </Grid>

                <Grid item xs={12}>
                    <PermissionsPicker
                        value={form.values.permissions}
                        onChange={(permissions) => onFormValuesUpdate({ permissions })}
                        disabled={loading}
                    />
                </Grid>
            </Grid>
        </Page>
    );
}
