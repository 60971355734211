import type { RouteDefinition} from '@/router';
import { RouteMetaProperty } from '@/router';
import { PlaygroundRootPage } from './pages/PlaygroundRoot';

export const RouteNames = {
    PLAYGROUND_HOME: 'PLAYGROUND_HOME',
} as const;

export const routes: RouteDefinition[] = [
    {
        name: RouteNames.PLAYGROUND_HOME,
        path: '/__playground__',
        Component: PlaygroundRootPage,
        meta: {
            [RouteMetaProperty.PAGE_TITLE]: 'Obszar testowy',
        },
    },
];
