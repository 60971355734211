import type { ButtonProps } from '@mui/material/Button';

export const PRIMARY_BUTTON: Partial<ButtonProps> = {
    variant: 'contained',
    color: 'primary',
};

export const SECONDARY_BUTTON: Partial<ButtonProps> = {
    variant: 'contained',
    color: 'info',
};

export const SUCCESS_BUTTON: Partial<ButtonProps> = {
    variant: 'contained',
    color: 'success',
};

export const DANGER_BUTTON: Partial<ButtonProps> = {
    variant: 'contained',
    color: 'error',
};
