import type { SidebarItem } from '../../hooks';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import { Icon } from '@mdi/react';
import { RouteMetaProperty, RouterLink, useCurrentRoute } from '@/router';

export interface SidebarListItemProps {
    item: SidebarItem;
    onClick?: () => void;
}

export function SidebarListItem({ item, onClick }: SidebarListItemProps) {
    const { name: currentRouteName, meta } = useCurrentRoute();
    const isSelected = currentRouteName === item.route.name
        || meta?.[RouteMetaProperty.SIDEBAR_ROUTE] === item.route.name;

    return (
        <RouterLink to={item.route}>
            <ListItem
                disablePadding
                sx={{ paddingRight: 1 }}
                onClick={onClick}
            >
                <ListItemButton
                    selected={isSelected}
                    sx={{
                        borderTopRightRadius: '25px',
                        borderBottomRightRadius: '25px',
                    }}
                >
                    <ListItemIcon>
                        <Icon path={item.icon} size={1} />
                    </ListItemIcon>
                    <ListItemText primary={item.label} />
                </ListItemButton>
            </ListItem>
        </RouterLink>
    );
}
