import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Icon from '@mdi/react';

export interface TitleWithActionProps {
    header: string;
    actionTitle: string;
    actionIcon: string;
    onActionClick?: () => void;
}

export function TitleWithAction({
    header,
    actionIcon,
    actionTitle,
    onActionClick,
}: TitleWithActionProps) {
    return (
        <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
        >
            <Typography variant="h6" fontWeight="regular" marginBottom={1}>
                {header}
            </Typography>

            {onActionClick && <Button
                startIcon={<Icon path={actionIcon} size={1} />}
                color="primary"
                size="small"
                onClick={onActionClick}
            >
                {actionTitle}
            </Button>}
        </Box>
    );
}
